import React, { useEffect, useState } from 'react'
import {
    FormWrapper,
    VehiclePriceWrapper,
    VehiclePrice,
    AuctionFee,
    AuctionLocationWrapper,
    AuctionLocation
} from './style'
import { Location } from '../auctionShippingLocation/index';
import { getShippingRate, getLiveBitFee } from '../../util/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AuctionDataSlice } from '../../store/slices/auctionDataSlice';

export const AuctionDataForm = () => {
    const { shippingPrice, vehiclePrice, selectedAuction } = useSelector((state) => state.auctionData)
    const { vehicleType } = useSelector((state) => state.carData)
    const { setVehiclePrice, setAuctionFee, setCurrentShippingPrice } = AuctionDataSlice.actions
    const dispatch = useDispatch()

    const [vehiclePriceState, setVehiclePriceState] = useState('')
    const [shippingPriceState, setShippingPriceState] = useState()
    const [totalFee, setTotalFee] = useState(0)
    const handlePriceChange = (e) => {
        const intPrice = parseInt(e.target.value)
        setVehiclePriceState(intPrice)
        dispatch(setVehiclePrice(intPrice))
    }

    useEffect(() => {
        if (vehiclePrice > 0 && vehiclePriceState) {
            const liveBitFee = getLiveBitFee(vehiclePriceState);
            const shippingRate = getShippingRate(vehiclePriceState);
            if (selectedAuction === 'copart') {
                setTotalFee(liveBitFee + shippingRate + 109)
                dispatch(setAuctionFee(liveBitFee + shippingRate + 109))
            } else if (selectedAuction === 'iaai') {
                setTotalFee(liveBitFee + shippingRate + 109 + 36)
                dispatch(setAuctionFee(liveBitFee + shippingRate + 109 + 36))
            } else {
                return;
            }
        } else {
            setTotalFee(0)
            dispatch(setAuctionFee(0))
        }
    }, [vehiclePriceState, dispatch, selectedAuction, setAuctionFee, vehiclePrice])

    useEffect(() => {
        if (shippingPrice.sedan > 100) {
            switch (vehicleType) {
                case 'sedan':
                    setShippingPriceState(shippingPrice.sedan + 100);
                    dispatch(setCurrentShippingPrice(shippingPrice.sedan + 100))
                    break;
                case 'suv':
                    setShippingPriceState(shippingPrice.suv + 100);
                    dispatch(setCurrentShippingPrice(shippingPrice.suv + 100))
                    break;
                case 'motorcycle':
                    setShippingPriceState(shippingPrice.motorcycle + 100);
                    dispatch(setCurrentShippingPrice(shippingPrice.motorcycle + 100))
                    break;
                case 'pickup':
                    setShippingPriceState(shippingPrice.pickup + 100);
                    dispatch(setCurrentShippingPrice(shippingPrice.pickup + 100))
                    break;
                case 'bigSuv':
                    setShippingPriceState(shippingPrice.bigSuv + 100);
                    dispatch(setCurrentShippingPrice(shippingPrice.bigSuv + 100))
                    break;
                case 'minivan':
                    setShippingPriceState(shippingPrice.van + 100);
                    dispatch(setCurrentShippingPrice(shippingPrice.van + 100))
                    break;
                default:
                    setShippingPriceState(shippingPrice.sedan + 100);
                    dispatch(setCurrentShippingPrice(shippingPrice.sedan + 100))
            }
        }
    }, [vehicleType, setShippingPriceState, shippingPrice, setCurrentShippingPrice, dispatch])
    return (
        <FormWrapper>
            <VehiclePriceWrapper>
                <div className='vehicle_price_input'>
                    <label className="vehicle_price" htmlFor="vehicle_price">Մեքենայի արժեք</label>
                    <AuctionFee className={vehiclePriceState ? "visible" : ""}>
                        <span>Աճուրդի վճար</span>
                        <p>{totalFee}</p>
                    </AuctionFee>
                    <VehiclePrice name='vehicle_price' id='vehicle_price' type='number' placeholder='0'
                        value={vehiclePriceState} onChange={handlePriceChange} required />
                </div>
            </VehiclePriceWrapper>
            <AuctionLocationWrapper>
                <div className='vehicle_location_input'>
                    <span>Աճուրդի վայր</span>
                    <AuctionLocation className={shippingPriceState ? "visible" : ""}>
                        <span>Տեղափոխման վճար</span>
                        <p>{shippingPriceState}</p>
                    </AuctionLocation>
                    <Location />
                </div>
            </AuctionLocationWrapper>
        </FormWrapper>
    )
}
