import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        font-family: "Noto Sans Armenian", sans-serif;
        box-sizing: border-box;
    }

    input, select {
        height: 40px;
        width: 99%;
        background-color: #ffffff;
        color: #577bd9;
        border-radius: 10px;
        font-size: 20px;
        border: 2px solid #cfcfcf;
        outline: none;
        padding-left: 10px;
        transition: 0.2s ease-in-out;
    }

    input:focus, select:focus {
        border: 2px solid #6586db;
    }
`

export default GlobalStyle