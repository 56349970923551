import React,{useEffect} from 'react'
import {locationDataSlice} from '../../store/slices/locationDataSlice';
import { useDispatch } from 'react-redux';
export const DataList = () => {
    const {setLocationData} = locationDataSlice.actions
    const dispatch = useDispatch()
    useEffect(() => {
            const selectElement = document.getElementById('cars');
            const options = selectElement.options;
            const optionsArray = Array.from(options);
            
            const extractedData = optionsArray.map(option => {
                const dataAttributes = {};
                for (const attr of option.attributes) {
                    if (attr.name.startsWith('data-')) {
                        dataAttributes[attr.name.replace('data-', '')] = attr.value;
                    }
                }
                return {
                    value: option.value,
                    label: option.text,
                    data: dataAttributes
                };
            });
        dispatch(setLocationData(extractedData));
        // eslint-disable-next-line
    }, []);
  return (
    <select style={{display:'none'}} id="cars" className="select">
                <option

                    data-sedan="2475"
                    data-suv="2575"
                    data-big_suv="2825"
                    data-van="2825"
                    data-pickup="3215"
                    data-motorcycle="1725"
                    value="2">AL-BIRMINGHAM</option>
                <option

                    data-sedan="2440"
                    data-suv="2540"
                    data-big_suv="2790"
                    data-van="2790"
                    data-pickup="3180"
                    data-motorcycle="1690"
                    value="3">AL-DOTHAN</option>
                <option

                    data-sedan="2580"
                    data-suv="2680"
                    data-big_suv="2930"
                    data-van="2930"
                    data-pickup="3320"
                    data-motorcycle="1830"
                    value="4">AL-HUNTSVILLE</option>
                <option

                    data-sedan="2475"
                    data-suv="2575"
                    data-big_suv="2825"
                    data-van="2825"
                    data-pickup="3215"
                    data-motorcycle="1725"
                    value="5">AL-MOBILE</option>
                <option

                    data-sedan="2475"
                    data-suv="2575"
                    data-big_suv="2825"
                    data-van="2825"
                    data-pickup="3215"
                    data-motorcycle="1725"
                    value="6">AL-MONTGOMERY</option>
                <option

                    data-sedan="2475"
                    data-suv="2575"
                    data-big_suv="2825"
                    data-van="2825"
                    data-pickup="3215"
                    data-motorcycle="1725"
                    value="7">AL-TANNER</option>
                <option

                    data-sedan="2550"
                    data-suv="2650"
                    data-big_suv="2900"
                    data-van="2900"
                    data-pickup="3200"
                    data-motorcycle="1700"
                    value="8">AR-FAYETTEVILLE COPART</option>
                <option

                    data-sedan="2300"
                    data-suv="2400"
                    data-big_suv="2650"
                    data-van="2650"
                    data-pickup="2950"
                    data-motorcycle="1450"
                    value="9">AR-FAYETTEVILLE IAA</option>
                <option

                    data-sedan="2450"
                    data-suv="2550"
                    data-big_suv="2800"
                    data-van="2800"
                    data-pickup="3050"
                    data-motorcycle="1350"
                    value="10">AR-LITTLE ROCK COPART</option>
                <option

                    data-sedan="2450"
                    data-suv="2550"
                    data-big_suv="2800"
                    data-van="2800"
                    data-pickup="3100"
                    data-motorcycle="1600"
                    value="11">AR-LITTLE ROCK IAA</option>
                <option

                    data-sedan="2960"
                    data-suv="3060"
                    data-big_suv="3310"
                    data-van="3310"
                    data-pickup="3770"
                    data-motorcycle="1810"
                    value="12">AZ-PHOENIX</option>
                <option

                    data-sedan="3040"
                    data-suv="3140"
                    data-big_suv="3390"
                    data-van="3390"
                    data-pickup="3850"
                    data-motorcycle="1890"
                    value="13">AZ-TUCSON</option>
                <option

                    data-sedan="2730"
                    data-suv="2830"
                    data-big_suv="3080"
                    data-van="3080"
                    data-pickup="3540"
                    data-motorcycle="1510"
                    value="15">CA- ACE Carson</option>
                <option

                    data-sedan="2730"
                    data-suv="2830"
                    data-big_suv="3080"
                    data-van="3080"
                    data-pickup="3540"
                    data-motorcycle="1580"
                    value="16">CA- ACE Perris</option>
                <option

                    data-sedan="2810"
                    data-suv="2910"
                    data-big_suv="3160"
                    data-van="3160"
                    data-pickup="3620"
                    data-motorcycle="1660"
                    value="14">CA-ADELANTO</option>
                <option

                    data-sedan="2660"
                    data-suv="2760"
                    data-big_suv="3010"
                    data-van="3010"
                    data-pickup="3390"
                    data-motorcycle="1430"
                    value="17">CA-ANAHEIM</option>
                <option

                    data-sedan="3010"
                    data-suv="3110"
                    data-big_suv="3360"
                    data-van="3360"
                    data-pickup="3820"
                    data-motorcycle="1860"
                    value="18">CA-ANTELOPE</option>
                <option

                    data-sedan="2770"
                    data-suv="2870"
                    data-big_suv="3120"
                    data-van="3120"
                    data-pickup="3580"
                    data-motorcycle="1460"
                    value="19">CA-BAKERSFIELD</option>
                <option

                    data-sedan="2705"
                    data-suv="2805"
                    data-big_suv="3055"
                    data-van="3055"
                    data-pickup="3405"
                    data-motorcycle="1475"
                    value="20">CA-COLTON</option>
                <option

                    data-sedan="3050"
                    data-suv="3150"
                    data-big_suv="3400"
                    data-van="3400"
                    data-pickup="3860"
                    data-motorcycle="1900"
                    value="21">CA-EAST BAY</option>
                <option

                    data-sedan="2730"
                    data-suv="2830"
                    data-big_suv="3080"
                    data-van="3080"
                    data-pickup="3540"
                    data-motorcycle="1580"
                    value="22">CA-FONTANA</option>
                <option

                    data-sedan="3050"
                    data-suv="3150"
                    data-big_suv="3400"
                    data-van="3400"
                    data-pickup="3860"
                    data-motorcycle="1900"
                    value="23">CA-FREMONT</option>
                <option

                    data-sedan="2850"
                    data-suv="2950"
                    data-big_suv="3200"
                    data-van="3200"
                    data-pickup="3660"
                    data-motorcycle="1700"
                    value="24">CA-FRESNO</option>
                <option

                    data-sedan="3010"
                    data-suv="3110"
                    data-big_suv="3360"
                    data-van="3360"
                    data-pickup="3820"
                    data-motorcycle="1860"
                    value="25">CA-HAYWARD</option>
                <option

                    data-sedan="3010"
                    data-suv="3110"
                    data-big_suv="3360"
                    data-van="3360"
                    data-pickup="3820"
                    data-motorcycle="1860"
                    value="26">CA-HAYWARD DESERT VIEW</option>
                <option

                    data-sedan="2770"
                    data-suv="2870"
                    data-big_suv="3120"
                    data-van="3120"
                    data-pickup="3580"
                    data-motorcycle="1460"
                    value="27">CA-HIGH DESERT</option>
                <option

                    data-sedan="2770"
                    data-suv="2870"
                    data-big_suv="3120"
                    data-van="3120"
                    data-pickup="3580"
                    data-motorcycle="1540"
                    value="28">CA-LONG BEACH</option>
                <option

                    data-sedan="2770"
                    data-suv="2870"
                    data-big_suv="3120"
                    data-van="3120"
                    data-pickup="3580"
                    data-motorcycle="1620"
                    value="29">CA-LOS ANGELES</option>
                <option

                    data-sedan="2970"
                    data-suv="3070"
                    data-big_suv="3320"
                    data-van="3320"
                    data-pickup="3780"
                    data-motorcycle="1820"
                    value="30">CA-MARTINEZ</option>
                <option

                    data-sedan="2660"
                    data-suv="2760"
                    data-big_suv="3010"
                    data-van="3010"
                    data-pickup="3390"
                    data-motorcycle="1430"
                    value="31">CA-NORTH HOLLYWOOD</option>
                <option

                    data-sedan="2730"
                    data-suv="2830"
                    data-big_suv="3080"
                    data-van="3080"
                    data-pickup="3540"
                    data-motorcycle="1580"
                    value="32">CA-RANCHO CUCAMONGA</option>
                <option

                    data-sedan="2640"
                    data-suv="2740"
                    data-big_suv="2990"
                    data-van="2990"
                    data-pickup="3370"
                    data-motorcycle="1410"
                    value="33">CA-RANCHO-DESERT VIEW</option>
                <option

                    data-sedan="3170"
                    data-suv="3270"
                    data-big_suv="3520"
                    data-van="3520"
                    data-pickup="3980"
                    data-motorcycle="2020"
                    value="34">CA-REDDING</option>
                <option

                    data-sedan="2810"
                    data-suv="2910"
                    data-big_suv="3160"
                    data-van="3160"
                    data-pickup="3660"
                    data-motorcycle="1660"
                    value="35">CA-RIVERSIDE</option>
                <option

                    data-sedan="2930"
                    data-suv="3030"
                    data-big_suv="3280"
                    data-van="3280"
                    data-pickup="3740"
                    data-motorcycle="1780"
                    value="36">CA-SACRAMENTO</option>
                <option

                    data-sedan="2930"
                    data-suv="3030"
                    data-big_suv="3280"
                    data-van="3280"
                    data-pickup="3740"
                    data-motorcycle="1780"
                    value="37">CA-SACRAMENTO-DESERT VIEW</option>
                <option

                    data-sedan="2705"
                    data-suv="2805"
                    data-big_suv="3055"
                    data-van="3055"
                    data-pickup="3435"
                    data-motorcycle="1475"
                    value="38">CA-SAN BERNARDINO</option>
                <option

                    data-sedan="2730"
                    data-suv="2830"
                    data-big_suv="3080"
                    data-van="3080"
                    data-pickup="3540"
                    data-motorcycle="1475"
                    value="39">CA-SAN DIEGO</option>
                <option

                    data-sedan="2730"
                    data-suv="2830"
                    data-big_suv="3080"
                    data-van="3080"
                    data-pickup="3435"
                    data-motorcycle="1475"
                    value="40">CA-SAN DIEGO-DESERT VIEW</option>
                <option

                    data-sedan="3050"
                    data-suv="3150"
                    data-big_suv="3400"
                    data-van="3400"
                    data-pickup="3860"
                    data-motorcycle="1900"
                    value="41">CA-SAN JOSE</option>
                <option

                    data-sedan="2705"
                    data-suv="2805"
                    data-big_suv="3055"
                    data-van="3055"
                    data-pickup="3435"
                    data-motorcycle="1475"
                    value="42">CA-SANTA PAULA-DESERT VIEW</option>
                <option

                    data-sedan="2930"
                    data-suv="3030"
                    data-big_suv="3280"
                    data-van="3280"
                    data-pickup="3740"
                    data-motorcycle="1780"
                    value="43">CA-SO SACRAMENTO</option>
                <option

                    data-sedan="2690"
                    data-suv="2790"
                    data-big_suv="3040"
                    data-van="3040"
                    data-pickup="3500"
                    data-motorcycle="1540"
                    value="44">CA-SUN VALLEY</option>
                <option

                    data-sedan="3010"
                    data-suv="3110"
                    data-big_suv="3360"
                    data-van="3360"
                    data-pickup="3740"
                    data-motorcycle="1860"
                    value="45">CA-VALLEJO</option>
                <option

                    data-sedan="2730"
                    data-suv="2830"
                    data-big_suv="3080"
                    data-van="3080"
                    data-pickup="3540"
                    data-motorcycle="1580"
                    value="46">CA-VAN NUYS</option>
                <option

                    data-sedan="2870"
                    data-suv="2970"
                    data-big_suv="3220"
                    data-van="3220"
                    data-pickup="3580"
                    data-motorcycle="2020"
                    value="47">CO-COLORADO SPRINGS</option>
                <option

                    data-sedan="2990"
                    data-suv="3090"
                    data-big_suv="3340"
                    data-van="3340"
                    data-pickup="3700"
                    data-motorcycle="2140"
                    value="48">CO-DENVER</option>
                <option

                    data-sedan="2950"
                    data-suv="3050"
                    data-big_suv="3300"
                    data-van="3300"
                    data-pickup="3660"
                    data-motorcycle="2100"
                    value="49">CO-DENVER CENTRAL</option>
                <option

                    data-sedan="2870"
                    data-suv="2970"
                    data-big_suv="3220"
                    data-van="3220"
                    data-pickup="3580"
                    data-motorcycle="2020"
                    value="50">CO-DENVER EAST</option>
                <option

                    data-sedan="2950"
                    data-suv="3050"
                    data-big_suv="3300"
                    data-van="3300"
                    data-pickup="3660"
                    data-motorcycle="2100"
                    value="51">CO-DENVER SOUTH</option>
                <option

                    data-sedan="3550"
                    data-suv="3650"
                    data-big_suv="3900"
                    data-van="3900"
                    data-pickup="4260"
                    data-motorcycle="2140"
                    value="52">CO-WESTERN COLORADO</option>
                <option

                    data-sedan="2270"
                    data-suv="2370"
                    data-big_suv="2570"
                    data-van="2570"
                    data-pickup="2960"
                    data-motorcycle="1400"
                    value="53">CT-HARTFORD</option>
                <option

                    data-sedan="2270"
                    data-suv="2370"
                    data-big_suv="2570"
                    data-van="2570"
                    data-pickup="2960"
                    data-motorcycle="1400"
                    value="54">CT-HARTFORD SPRINGFIELD</option>
                <option

                    data-sedan="2270"
                    data-suv="2370"
                    data-big_suv="2570"
                    data-van="2570"
                    data-pickup="2960"
                    data-motorcycle="1400"
                    value="55">CT-Hartford-South</option>
                <option

                    data-sedan="2270"
                    data-suv="2370"
                    data-big_suv="2570"
                    data-van="2570"
                    data-pickup="2960"
                    data-motorcycle="1470"
                    value="56">DC-WASHINGTON DC-MD</option>
                <option

                    data-sedan="2200"
                    data-suv="2300"
                    data-big_suv="2500"
                    data-van="2500"
                    data-pickup="2850"
                    data-motorcycle="1300"
                    value="57">DE-NEW CASTLE</option>
                <option

                    data-sedan="2250"
                    data-suv="2350"
                    data-big_suv="2550"
                    data-van="2550"
                    data-pickup="2950"
                    data-motorcycle="1350"
                    value="58">DE-SEAFORD</option>
                <option

                    data-sedan="2255"
                    data-suv="2355"
                    data-big_suv="2580"
                    data-van="2580"
                    data-pickup="3120"
                    data-motorcycle="1330"
                    value="59">FL-CAT Jacksonville Sub lot</option>
                <option

                    data-sedan="2360"
                    data-suv="2460"
                    data-big_suv="2685"
                    data-van="2685"
                    data-pickup="3225"
                    data-motorcycle="1435"
                    value="60">FL-CAT Orlando Boggy  Creek</option>
                <option

                    data-sedan="2255"
                    data-suv="2355"
                    data-big_suv="2580"
                    data-van="2580"
                    data-pickup="3120"
                    data-motorcycle="1350"
                    value="62">FL-CLEARWATER</option>
                <option

                    data-sedan="2325"
                    data-suv="2425"
                    data-big_suv="2650"
                    data-van="2650"
                    data-pickup="3190"
                    data-motorcycle="1400"
                    value="61">FL-Dover Sublot</option>
                <option

                    data-sedan="2395"
                    data-suv="2495"
                    data-big_suv="2720"
                    data-van="2720"
                    data-pickup="3360"
                    data-motorcycle="1470"
                    value="63">FL-FORT MYERS</option>
                <option

                    data-sedan="2570"
                    data-suv="2670"
                    data-big_suv="2895"
                    data-van="2895"
                    data-pickup="3435"
                    data-motorcycle="1645"
                    value="64">FL-FORT PIERCE</option>
                <option

                    data-sedan="2325"
                    data-suv="2425"
                    data-big_suv="2650"
                    data-van="2650"
                    data-pickup="3190"
                    data-motorcycle="1400"
                    value="65">FL-FT.PIERCE</option>
                <option

                    data-sedan="2255"
                    data-suv="2355"
                    data-big_suv="2580"
                    data-van="2580"
                    data-pickup="3120"
                    data-motorcycle="1330"
                    value="66">FL-JACKSONVILLE</option>
                <option

                    data-sedan="2255"
                    data-suv="2355"
                    data-big_suv="2580"
                    data-van="2580"
                    data-pickup="3120"
                    data-motorcycle="1330"
                    value="67">FL-JACKSONVILLE EAST</option>
                <option

                    data-sedan="2255"
                    data-suv="2355"
                    data-big_suv="2580"
                    data-van="2580"
                    data-pickup="3120"
                    data-motorcycle="1330"
                    value="68">FL-JACKSONVILLE WEST</option>
                <option

                    data-sedan="2145"
                    data-suv="2245"
                    data-big_suv="2470"
                    data-van="2470"
                    data-pickup="2940"
                    data-motorcycle="1150"
                    value="69">FL-MIAMI</option>
                <option

                    data-sedan="2220"
                    data-suv="2320"
                    data-big_suv="2545"
                    data-van="2545"
                    data-pickup="3085"
                    data-motorcycle="1295"
                    value="70">FL-MIAMI CENTRAL</option>
                <option

                    data-sedan="2145"
                    data-suv="2245"
                    data-big_suv="2470"
                    data-van="2470"
                    data-pickup="2940"
                    data-motorcycle="1150"
                    value="71">FL-MIAMI NORTH</option>
                <option

                    data-sedan="2130"
                    data-suv="2230"
                    data-big_suv="2455"
                    data-van="2455"
                    data-pickup="2925"
                    data-motorcycle="1135"
                    value="72">FL-MIAMI SOUTH</option>
                <option

                    data-sedan="2430"
                    data-suv="2530"
                    data-big_suv="2755"
                    data-van="2755"
                    data-pickup="3295"
                    data-motorcycle="1505"
                    value="73">FL-OCALA</option>
                <option

                    data-sedan="2325"
                    data-suv="2425"
                    data-big_suv="2650"
                    data-van="2650"
                    data-pickup="3190"
                    data-motorcycle="1400"
                    value="74">FL-ORLANDO</option>
                <option

                    data-sedan="2325"
                    data-suv="2425"
                    data-big_suv="2650"
                    data-van="2650"
                    data-pickup="3190"
                    data-motorcycle="1400"
                    value="75">FL-ORLANDO NORTH</option>
                <option

                    data-sedan="2360"
                    data-suv="2460"
                    data-big_suv="2685"
                    data-van="2685"
                    data-pickup="3225"
                    data-motorcycle="1435"
                    value="76">FL-ORLANDO SOUTH</option>
                <option

                    data-sedan="2360"
                    data-suv="2460"
                    data-big_suv="2685"
                    data-van="2685"
                    data-pickup="3225"
                    data-motorcycle="1435"
                    value="77">FL-PENSACOLA</option>
                <option

                    data-sedan="2255"
                    data-suv="2355"
                    data-big_suv="2580"
                    data-van="2580"
                    data-pickup="3120"
                    data-motorcycle="1330"
                    value="78">FL-PUNTA GORDA</option>
                <option

                    data-sedan="2430"
                    data-suv="2530"
                    data-big_suv="2755"
                    data-van="2755"
                    data-pickup="3295"
                    data-motorcycle="1505"
                    value="79">FL-TALLAHASSEE</option>
                <option

                    data-sedan="2290"
                    data-suv="2390"
                    data-big_suv="2615"
                    data-van="2615"
                    data-pickup="3120"
                    data-motorcycle="1350"
                    value="80">FL-TAMPA</option>
                <option

                    data-sedan="2145"
                    data-suv="2245"
                    data-big_suv="2470"
                    data-van="2470"
                    data-pickup="2940"
                    data-motorcycle="1150"
                    value="81">FL-WEST PALM BEACH</option>
                <option

                    data-sedan="2185"
                    data-suv="2285"
                    data-big_suv="2535"
                    data-van="2535"
                    data-pickup="2925"
                    data-motorcycle="1435"
                    value="82">GA-ATLANTA</option>
                <option

                    data-sedan="2395"
                    data-suv="2495"
                    data-big_suv="2745"
                    data-van="2745"
                    data-pickup="3135"
                    data-motorcycle="1645"
                    value="83">GA-ATLANTA EAST</option>
                <option

                    data-sedan="2325"
                    data-suv="2425"
                    data-big_suv="2675"
                    data-van="2675"
                    data-pickup="3065"
                    data-motorcycle="1575"
                    value="84">GA-ATLANTA NORTH</option>
                <option

                    data-sedan="2185"
                    data-suv="2285"
                    data-big_suv="2535"
                    data-van="2535"
                    data-pickup="2925"
                    data-motorcycle="1435"
                    value="85">GA-ATLANTA SOUTH</option>
                <option

                    data-sedan="2255"
                    data-suv="2355"
                    data-big_suv="2605"
                    data-van="2605"
                    data-pickup="2995"
                    data-motorcycle="1505"
                    value="86">GA-ATLANTA WEST</option>
                <option

                    data-sedan="2325"
                    data-suv="2425"
                    data-big_suv="2675"
                    data-van="2675"
                    data-pickup="3065"
                    data-motorcycle="1575"
                    value="87">GA-CARTERSVILLE</option>
                <option

                    data-sedan="2255"
                    data-suv="2355"
                    data-big_suv="2580"
                    data-van="2580"
                    data-pickup="3120"
                    data-motorcycle="1190"
                    value="88">GA-FAIRBURN</option>
                <option

                    data-sedan="2255"
                    data-suv="2355"
                    data-big_suv="2605"
                    data-van="2605"
                    data-pickup="2995"
                    data-motorcycle="1505"
                    value="89">GA-MACON</option>
                <option

                    data-sedan="1970"
                    data-suv="2070"
                    data-big_suv="2320"
                    data-van="2320"
                    data-pickup="2640"
                    data-motorcycle="1150"
                    value="90">GA-SAVANNAH</option>
                <option

                    data-sedan="2185"
                    data-suv="2285"
                    data-big_suv="2535"
                    data-van="2535"
                    data-pickup="2925"
                    data-motorcycle="1435"
                    value="91">GA-TIFTON</option>
                <option

                    data-sedan="4830"
                    data-suv="4930"
                    data-big_suv="5180"
                    data-van="5180"
                    data-pickup="6040"
                    data-motorcycle="3120"
                    value="92">HI-HONOLULU</option>
                <option

                    data-sedan="2700"
                    data-suv="2800"
                    data-big_suv="3000"
                    data-van="3000"
                    data-pickup="3400"
                    data-motorcycle="1800"
                    value="93">IA-Crashedtoys Eldridge</option>
                <option

                    data-sedan="2700"
                    data-suv="2800"
                    data-big_suv="3000"
                    data-van="3000"
                    data-pickup="3400"
                    data-motorcycle="1800"
                    value="94">IA-DAVENPORT</option>
                <option

                    data-sedan="2700"
                    data-suv="2800"
                    data-big_suv="3000"
                    data-van="3000"
                    data-pickup="3400"
                    data-motorcycle="1800"
                    value="95">IA-DES MOINES</option>
                <option

                    data-sedan="3050"
                    data-suv="3150"
                    data-big_suv="3400"
                    data-van="3400"
                    data-pickup="3850"
                    data-motorcycle="1900"
                    value="96">ID-BOISE</option>
                <option

                    data-sedan="2550"
                    data-suv="2650"
                    data-big_suv="2850"
                    data-van="2850"
                    data-pickup="3310"
                    data-motorcycle="1500"
                    value="97">IL-CHICAGO NORTH</option>
                <option

                    data-sedan="2550"
                    data-suv="2650"
                    data-big_suv="2850"
                    data-van="2850"
                    data-pickup="3310"
                    data-motorcycle="1500"
                    value="98">IL-CHICAGO SOUTH</option>
                <option

                    data-sedan="2550"
                    data-suv="2650"
                    data-big_suv="2850"
                    data-van="2850"
                    data-pickup="3240"
                    data-motorcycle="1555"
                    value="99">IL-CHICAGO WEST</option>
                <option

                    data-sedan="2690"
                    data-suv="2790"
                    data-big_suv="2990"
                    data-van="2990"
                    data-pickup="3380"
                    data-motorcycle="1890"
                    value="100">IL-LINCOLN</option>
                <option

                    data-sedan="2520"
                    data-suv="2620"
                    data-big_suv="2820"
                    data-van="2820"
                    data-pickup="3350"
                    data-motorcycle="1440"
                    value="102">IL-PEORIA</option>
                <option

                    data-sedan="2585"
                    data-suv="2685"
                    data-big_suv="2885"
                    data-van="2885"
                    data-pickup="3245"
                    data-motorcycle="1785"
                    value="103">IL-SOUTHERN ILLINOIS</option>
                <option

                    data-sedan="2480"
                    data-suv="2580"
                    data-big_suv="2780"
                    data-van="2780"
                    data-pickup="3170"
                    data-motorcycle="1540"
                    value="101">IL-SPECIALTY DIVISION</option>
                <option

                    data-sedan="2650"
                    data-suv="2750"
                    data-big_suv="2950"
                    data-van="2950"
                    data-pickup="3340"
                    data-motorcycle="1750"
                    value="104">IL-ST. LOUIS</option>
                <option

                    data-sedan="2465"
                    data-suv="2565"
                    data-big_suv="2765"
                    data-van="2765"
                    data-pickup="3155"
                    data-motorcycle="1525"
                    value="105">IL-WHEELING</option>
                <option

                    data-sedan="2515"
                    data-suv="2615"
                    data-big_suv="2815"
                    data-van="2815"
                    data-pickup="3205"
                    data-motorcycle="1715"
                    value="113">IN- IAA Indy Minnesota Street Sublot</option>
                <option

                    data-sedan="2585"
                    data-suv="2685"
                    data-big_suv="2885"
                    data-van="2885"
                    data-pickup="3275"
                    data-motorcycle="1785"
                    value="106">IN-CICERO</option>
                <option

                    data-sedan="2585"
                    data-suv="2685"
                    data-big_suv="2885"
                    data-van="2885"
                    data-pickup="3275"
                    data-motorcycle="1785"
                    value="107">IN-DYER</option>
                <option

                    data-sedan="2585"
                    data-suv="2685"
                    data-big_suv="2885"
                    data-van="2885"
                    data-pickup="3245"
                    data-motorcycle="1785"
                    value="108">IN-FORT WAYNE</option>
                <option

                    data-sedan="2585"
                    data-suv="2685"
                    data-big_suv="2885"
                    data-van="2885"
                    data-pickup="3150"
                    data-motorcycle="1680"
                    value="109">IN-HAMMOND</option>
                <option

                    data-sedan="2480"
                    data-suv="2580"
                    data-big_suv="2780"
                    data-van="2780"
                    data-pickup="3060"
                    data-motorcycle="1590"
                    value="110">IN-HARTFORD CITY</option>
                <option

                    data-sedan="2550"
                    data-suv="2650"
                    data-big_suv="2850"
                    data-van="2850"
                    data-pickup="3240"
                    data-motorcycle="1750"
                    value="111">IN-INDIANAPOLIS</option>
                <option

                    data-sedan="2550"
                    data-suv="2650"
                    data-big_suv="2850"
                    data-van="2850"
                    data-pickup="3240"
                    data-motorcycle="1750"
                    value="112">IN-SOUTH BEND</option>
                <option

                    data-sedan="2710"
                    data-suv="2810"
                    data-big_suv="3060"
                    data-van="3060"
                    data-pickup="3420"
                    data-motorcycle="1860"
                    value="114">KS-KANSAS CITY</option>
                <option

                    data-sedan="2510"
                    data-suv="2610"
                    data-big_suv="2860"
                    data-van="2860"
                    data-pickup="3220"
                    data-motorcycle="1660"
                    value="115">KS-WICHITA</option>
                <option

                    data-sedan="2620"
                    data-suv="2720"
                    data-big_suv="2920"
                    data-van="2920"
                    data-pickup="3310"
                    data-motorcycle="1820"
                    value="116">KY-ASHLAND</option>
                <option

                    data-sedan="2690"
                    data-suv="2790"
                    data-big_suv="2990"
                    data-van="2990"
                    data-pickup="3380"
                    data-motorcycle="1890"
                    value="117">KY-BOWLING GREEN</option>
                <option

                    data-sedan="2585"
                    data-suv="2685"
                    data-big_suv="2885"
                    data-van="2885"
                    data-pickup="3275"
                    data-motorcycle="1785"
                    value="118">KY-LEXINGTON EAST</option>
                <option

                    data-sedan="2585"
                    data-suv="2685"
                    data-big_suv="2885"
                    data-van="2885"
                    data-pickup="3275"
                    data-motorcycle="1785"
                    value="119">KY-LEXINGTON WEST</option>
                <option

                    data-sedan="2625"
                    data-suv="2725"
                    data-big_suv="2925"
                    data-van="2925"
                    data-pickup="3310"
                    data-motorcycle="1820"
                    value="120">KY-LOUISVILLE</option>
                <option

                    data-sedan="2830"
                    data-suv="2930"
                    data-big_suv="3130"
                    data-van="3130"
                    data-pickup="3520"
                    data-motorcycle="1680"
                    value="121">KY-PADUCAH</option>
                <option

                    data-sedan="2620"
                    data-suv="2720"
                    data-big_suv="2920"
                    data-van="2920"
                    data-pickup="3310"
                    data-motorcycle="1610"
                    value="122">KY-WALTON</option>
                <option

                    data-sedan="2405"
                    data-suv="2505"
                    data-big_suv="2755"
                    data-van="2755"
                    data-pickup="3010"
                    data-motorcycle="1485"
                    value="126">LA- LAFAYETTE</option>
                <option

                    data-sedan="2440"
                    data-suv="2540"
                    data-big_suv="2790"
                    data-van="2790"
                    data-pickup="3040"
                    data-motorcycle="1590"
                    value="127">LA- NEW ORLEANS EAST</option>
                <option

                    data-sedan="2475"
                    data-suv="2575"
                    data-big_suv="2825"
                    data-van="2825"
                    data-pickup="3165"
                    data-motorcycle="1625"
                    value="123">LA-BATON ROUGE</option>
                <option

                    data-sedan="2440"
                    data-suv="2540"
                    data-big_suv="2790"
                    data-van="2790"
                    data-pickup="3130"
                    data-motorcycle="1590"
                    value="124">LA-NEW ORLEANS</option>
                <option

                    data-sedan="2405"
                    data-suv="2505"
                    data-big_suv="2755"
                    data-van="2755"
                    data-pickup="3095"
                    data-motorcycle="1450"
                    value="125">LA-SHREVEPORT</option>
                <option

                    data-sedan="2305"
                    data-suv="2405"
                    data-big_suv="2605"
                    data-van="2605"
                    data-pickup="3000"
                    data-motorcycle="1505"
                    value="128">MA -TEMPLETON</option>
                <option

                    data-sedan="2305"
                    data-suv="2405"
                    data-big_suv="2605"
                    data-van="2605"
                    data-pickup="2995"
                    data-motorcycle="1505"
                    value="129">MA-BOSTON SHIRLEY</option>
                <option

                    data-sedan="2360"
                    data-suv="2460"
                    data-big_suv="2710"
                    data-van="2710"
                    data-pickup="3100"
                    data-motorcycle="1610"
                    value="130">MA-FREETOWN</option>
                <option

                    data-sedan="2305"
                    data-suv="2405"
                    data-big_suv="2605"
                    data-van="2605"
                    data-pickup="2995"
                    data-motorcycle="1505"
                    value="131">MA-NORTH BOSTON</option>
                <option

                    data-sedan="2305"
                    data-suv="2405"
                    data-big_suv="2605"
                    data-van="2605"
                    data-pickup="2995"
                    data-motorcycle="1505"
                    value="132">MA-SOUTH BOSTON</option>
                <option

                    data-sedan="2305"
                    data-suv="2405"
                    data-big_suv="2605"
                    data-van="2605"
                    data-pickup="2995"
                    data-motorcycle="1505"
                    value="133">MA-TAUNTON</option>
                <option

                    data-sedan="2445"
                    data-suv="2545"
                    data-big_suv="2745"
                    data-van="2745"
                    data-pickup="3135"
                    data-motorcycle="1560"
                    value="134">MA-WEST WARREN</option>
                <option

                    data-sedan="2270"
                    data-suv="2370"
                    data-big_suv="2570"
                    data-van="2570"
                    data-pickup="2960"
                    data-motorcycle="1470"
                    value="140">MD White Plans Aux Lot</option>
                <option

                    data-sedan="2270"
                    data-suv="2370"
                    data-big_suv="2570"
                    data-van="2570"
                    data-pickup="2960"
                    data-motorcycle="1470"
                    value="135">MD-BALTIMORE</option>
                <option

                    data-sedan="2270"
                    data-suv="2370"
                    data-big_suv="2570"
                    data-van="2570"
                    data-pickup="2960"
                    data-motorcycle="1470"
                    value="136">MD-DUNDALK</option>
                <option

                    data-sedan="2320"
                    data-suv="2420"
                    data-big_suv="2620"
                    data-van="2620"
                    data-pickup="3010"
                    data-motorcycle="1520"
                    value="303">MD-ELKTON</option>
                <option

                    data-sedan="2270"
                    data-suv="2370"
                    data-big_suv="2570"
                    data-van="2570"
                    data-pickup="2960"
                    data-motorcycle="1470"
                    value="137">MD-LAUREL</option>
                <option

                    data-sedan="2270"
                    data-suv="2370"
                    data-big_suv="2570"
                    data-van="2570"
                    data-pickup="2960"
                    data-motorcycle="1470"
                    value="138">MD-METRO DC</option>
                <option

                    data-sedan="2270"
                    data-suv="2370"
                    data-big_suv="2570"
                    data-van="2570"
                    data-pickup="2960"
                    data-motorcycle="1470"
                    value="139">MD-ROSEDALE</option>
                <option

                    data-sedan="2230"
                    data-suv="2330"
                    data-big_suv="2530"
                    data-van="2530"
                    data-pickup="2880"
                    data-motorcycle="1430"
                    value="141">ME- Gray Sub lot</option>
                <option

                    data-sedan="2520"
                    data-suv="2620"
                    data-big_suv="2820"
                    data-van="2820"
                    data-pickup="3210"
                    data-motorcycle="1720"
                    value="142">ME-LYMAN</option>
                <option

                    data-sedan="2655"
                    data-suv="2755"
                    data-big_suv="2955"
                    data-van="2955"
                    data-pickup="3485"
                    data-motorcycle="1575"
                    value="143">ME-ORONO</option>
                <option

                    data-sedan="2515"
                    data-suv="2615"
                    data-big_suv="2815"
                    data-van="2815"
                    data-pickup="3205"
                    data-motorcycle="1715"
                    value="144">ME-PORTLAND GORHAM</option>
                <option

                    data-sedan="2570"
                    data-suv="2670"
                    data-big_suv="3010"
                    data-van="3010"
                    data-pickup="3260"
                    data-motorcycle="1770"
                    value="302">ME-WINDHAM</option>
                <option

                    data-sedan="2875"
                    data-suv="2975"
                    data-big_suv="3175"
                    data-van="3175"
                    data-pickup="3565"
                    data-motorcycle="2075"
                    value="145">MI-DETROIT</option>
                <option

                    data-sedan="2735"
                    data-suv="2835"
                    data-big_suv="3035"
                    data-van="3035"
                    data-pickup="3425"
                    data-motorcycle="1935"
                    value="146">MI-FLINT</option>
                <option

                    data-sedan="2840"
                    data-suv="2940"
                    data-big_suv="3140"
                    data-van="3140"
                    data-pickup="3530"
                    data-motorcycle="2040"
                    value="147">MI-GRAND RAPIDS</option>
                <option

                    data-sedan="2740"
                    data-suv="2840"
                    data-big_suv="3040"
                    data-van="3040"
                    data-pickup="3570"
                    data-motorcycle="1660"
                    value="148">MI-GREAT LAKES</option>
                <option

                    data-sedan="2700"
                    data-suv="2800"
                    data-big_suv="3000"
                    data-van="3000"
                    data-pickup="3500"
                    data-motorcycle="1900"
                    value="149">MI-IONIA</option>
                <option

                    data-sedan="3015"
                    data-suv="3115"
                    data-big_suv="3315"
                    data-van="3315"
                    data-pickup="3705"
                    data-motorcycle="2215"
                    value="150">MI-KINCHELOE</option>
                <option

                    data-sedan="2685"
                    data-suv="2785"
                    data-big_suv="2985"
                    data-van="2985"
                    data-pickup="3515"
                    data-motorcycle="1605"
                    value="151">MI-LANSING</option>
                <option

                    data-sedan="2725"
                    data-suv="2825"
                    data-big_suv="3025"
                    data-van="3025"
                    data-pickup="3415"
                    data-motorcycle="1925"
                    value="156">MN- IAA Faribault</option>
                <option

                    data-sedan="2585"
                    data-suv="2685"
                    data-big_suv="2885"
                    data-van="2885"
                    data-pickup="3275"
                    data-motorcycle="1785"
                    value="152">MN-Big Lake Sub lot</option>
                <option

                    data-sedan="2535"
                    data-suv="2635"
                    data-big_suv="2835"
                    data-van="2835"
                    data-pickup="3365"
                    data-motorcycle="1455"
                    value="153">MN-CRASHEDTOYS EAST BETHEL</option>
                <option

                    data-sedan="2660"
                    data-suv="2760"
                    data-big_suv="2960"
                    data-van="2960"
                    data-pickup="3350"
                    data-motorcycle="1860"
                    value="154">MN-Ham lake Sun lot</option>
                <option

                    data-sedan="2660"
                    data-suv="2760"
                    data-big_suv="2960"
                    data-van="2960"
                    data-pickup="3490"
                    data-motorcycle="1580"
                    value="155">MN-MINNEAPOLIS</option>
                <option

                    data-sedan="2660"
                    data-suv="2760"
                    data-big_suv="2960"
                    data-van="2960"
                    data-pickup="3350"
                    data-motorcycle="1860"
                    value="157">MN-MINNEAPOLIS NORTH</option>
                <option

                    data-sedan="2760"
                    data-suv="2860"
                    data-big_suv="3060"
                    data-van="3060"
                    data-pickup="3450"
                    data-motorcycle="1960"
                    value="158">MN-ST. CLOUD</option>
                <option

                    data-sedan="2725"
                    data-suv="2825"
                    data-big_suv="3025"
                    data-van="3025"
                    data-pickup="3415"
                    data-motorcycle="1925"
                    value="159">MO-COLUMBIA</option>
                <option

                    data-sedan="2650"
                    data-suv="2750"
                    data-big_suv="3000"
                    data-van="3000"
                    data-pickup="3340"
                    data-motorcycle="1800"
                    value="160">MO-SIKESTON</option>
                <option

                    data-sedan="2720"
                    data-suv="2820"
                    data-big_suv="3070"
                    data-van="3070"
                    data-pickup="3340"
                    data-motorcycle="1870"
                    value="161">MO-SPRINGFIELD</option>
                <option

                    data-sedan="2585"
                    data-suv="2685"
                    data-big_suv="2885"
                    data-van="2885"
                    data-pickup="3245"
                    data-motorcycle="1785"
                    value="162">MO-ST. LOUIS</option>
                <option

                    data-sedan="2545"
                    data-suv="2645"
                    data-big_suv="2895"
                    data-van="2895"
                    data-pickup="3200"
                    data-motorcycle="1520"
                    value="163">MS-GRENADA</option>
                <option

                    data-sedan="2440"
                    data-suv="2540"
                    data-big_suv="2790"
                    data-van="2790"
                    data-pickup="3130"
                    data-motorcycle="1520"
                    value="164">MS-GULF COAST</option>
                <option

                    data-sedan="2440"
                    data-suv="2540"
                    data-big_suv="2790"
                    data-van="2790"
                    data-pickup="3130"
                    data-motorcycle="1590"
                    value="165">MS-JACKSON</option>
                <option

                    data-sedan="3600"
                    data-suv="3700"
                    data-big_suv="3900"
                    data-van="3900"
                    data-pickup="4290"
                    data-motorcycle="2800"
                    value="166">MT-BILLINGS</option>
                <option

                    data-sedan="3570"
                    data-suv="3670"
                    data-big_suv="3920"
                    data-van="3920"
                    data-pickup="4360"
                    data-motorcycle="2420"
                    value="167">MT-HELENA</option>
                <option

                    data-sedan="3460"
                    data-suv="3560"
                    data-big_suv="3760"
                    data-van="3760"
                    data-pickup="4290"
                    data-motorcycle="2660"
                    value="168">MT-MISSOULA</option>
                <option

                    data-sedan="2290"
                    data-suv="2390"
                    data-big_suv="2640"
                    data-van="2640"
                    data-pickup="3030"
                    data-motorcycle="1540"
                    value="169">NC-ASHEVILLE</option>
                <option

                    data-sedan="2290"
                    data-suv="2390"
                    data-big_suv="2640"
                    data-van="2640"
                    data-pickup="3030"
                    data-motorcycle="1540"
                    value="170">NC-CHARLOTTE</option>
                <option

                    data-sedan="2430"
                    data-suv="2530"
                    data-big_suv="2780"
                    data-van="2780"
                    data-pickup="3210"
                    data-motorcycle="1680"
                    value="171">NC-CHINA GROVE</option>
                <option

                    data-sedan="2325"
                    data-suv="2425"
                    data-big_suv="2675"
                    data-van="2675"
                    data-pickup="3065"
                    data-motorcycle="1575"
                    value="172">NC-CONCORD</option>
                <option

                    data-sedan="2290"
                    data-suv="2390"
                    data-big_suv="2640"
                    data-van="2640"
                    data-pickup="3030"
                    data-motorcycle="1540"
                    value="173">NC-GREENSBORO</option>
                <option

                    data-sedan="2115"
                    data-suv="2215"
                    data-big_suv="2465"
                    data-van="2465"
                    data-pickup="2855"
                    data-motorcycle="1365"
                    value="174">NC-LUMBERTON</option>
                <option

                    data-sedan="2255"
                    data-suv="2355"
                    data-big_suv="2605"
                    data-van="2605"
                    data-pickup="2985"
                    data-motorcycle="1505"
                    value="175">NC-MEBANE</option>
                <option

                    data-sedan="2220"
                    data-suv="2320"
                    data-big_suv="2570"
                    data-van="2570"
                    data-pickup="2940"
                    data-motorcycle="1470"
                    value="177">NC-MOCKSVILLE</option>
                <option

                    data-sedan="2430"
                    data-suv="2530"
                    data-big_suv="2780"
                    data-van="2780"
                    data-pickup="3030"
                    data-motorcycle="1680"
                    value="176">NC-RALEIGH</option>
                <option

                    data-sedan="2185"
                    data-suv="2285"
                    data-big_suv="2535"
                    data-van="2535"
                    data-pickup="2925"
                    data-motorcycle="1435"
                    value="178">NC-WILMINGTON</option>
                <option

                    data-sedan="3610"
                    data-suv="3710"
                    data-big_suv="3910"
                    data-van="3910"
                    data-pickup="4800"
                    data-motorcycle="2490"
                    value="179">ND-BISMARCK</option>
                <option

                    data-sedan="3210"
                    data-suv="3310"
                    data-big_suv="3510"
                    data-van="3510"
                    data-pickup="4000"
                    data-motorcycle="2410"
                    value="180">ND-FARGO</option>
                <option

                    data-sedan="2690"
                    data-suv="2790"
                    data-big_suv="2990"
                    data-van="2990"
                    data-pickup="3590"
                    data-motorcycle="1890"
                    value="181">NE-LINCOLN</option>
                <option

                    data-sedan="2900"
                    data-suv="3000"
                    data-big_suv="3200"
                    data-van="3200"
                    data-pickup="3590"
                    data-motorcycle="2100"
                    value="182">NE-OMAHA</option>
                <option

                    data-sedan="2340"
                    data-suv="2440"
                    data-big_suv="2640"
                    data-van="2640"
                    data-pickup="2850"
                    data-motorcycle="1540"
                    value="184">NH- Salem Sub lot</option>
                <option

                    data-sedan="2340"
                    data-suv="2440"
                    data-big_suv="2640"
                    data-van="2640"
                    data-pickup="3030"
                    data-motorcycle="1400"
                    value="183">NH-CANDIA</option>
                <option

                    data-sedan="2375"
                    data-suv="2475"
                    data-big_suv="2675"
                    data-van="2675"
                    data-pickup="3065"
                    data-motorcycle="1575"
                    value="185">NH-MANCHESTER</option>
                <option

                    data-sedan="2340"
                    data-suv="2440"
                    data-big_suv="2640"
                    data-van="2640"
                    data-pickup="3030"
                    data-motorcycle="1400"
                    value="186">NH-WEBSTER</option>
                <option

                    data-sedan="2025"
                    data-suv="2125"
                    data-big_suv="2325"
                    data-van="2325"
                    data-pickup="2645"
                    data-motorcycle="1155"
                    value="188">NJ-AVENEL NEW JERSEY</option>
                <option

                    data-sedan="2020"
                    data-suv="2120"
                    data-big_suv="2320"
                    data-van="2320"
                    data-pickup="2640"
                    data-motorcycle="1150"
                    value="189">NJ-CENTRAL NEW JERSEY</option>
                <option

                    data-sedan="2025"
                    data-suv="2125"
                    data-big_suv="2325"
                    data-van="2325"
                    data-pickup="2715"
                    data-motorcycle="1225"
                    value="190">NJ-ENGLISHTOWN</option>
                <option

                    data-sedan="2075"
                    data-suv="2175"
                    data-big_suv="2375"
                    data-van="2375"
                    data-pickup="2765"
                    data-motorcycle="1275"
                    value="191">NJ-GLASSBORO EAST</option>
                <option

                    data-sedan="2075"
                    data-suv="2175"
                    data-big_suv="2375"
                    data-van="2375"
                    data-pickup="2765"
                    data-motorcycle="1275"
                    value="192">NJ-GLASSBORO WEST</option>
                <option

                    data-sedan="1990"
                    data-suv="2090"
                    data-big_suv="2290"
                    data-van="2290"
                    data-pickup="2610"
                    data-motorcycle="1120"
                    value="193">NJ-NORTHERN NEW JERSEY</option>
                <option

                    data-sedan="2030"
                    data-suv="2130"
                    data-big_suv="2330"
                    data-van="2330"
                    data-pickup="2640"
                    data-motorcycle="1190"
                    value="187">NJ-SAYREVILLE</option>
                <option

                    data-sedan="2030"
                    data-suv="2130"
                    data-big_suv="2330"
                    data-van="2330"
                    data-pickup="2720"
                    data-motorcycle="1230"
                    value="194">NJ-SOMERVILLE</option>
                <option

                    data-sedan="2165"
                    data-suv="2265"
                    data-big_suv="2465"
                    data-van="2465"
                    data-pickup="2790"
                    data-motorcycle="1365"
                    value="195">NJ-SOUTHERN NEW JERSEY</option>
                <option

                    data-sedan="2060"
                    data-suv="2160"
                    data-big_suv="2360"
                    data-van="2360"
                    data-pickup="2640"
                    data-motorcycle="1260"
                    value="196">NJ-TRENTON</option>
                <option

                    data-sedan="2860"
                    data-suv="2960"
                    data-big_suv="3210"
                    data-van="3210"
                    data-pickup="3540"
                    data-motorcycle="1630"
                    value="197">NM-ALBUQUERQUE</option>
                <option

                    data-sedan="3010"
                    data-suv="3110"
                    data-big_suv="3360"
                    data-van="3360"
                    data-pickup="3820"
                    data-motorcycle="1860"
                    value="198">NV-LAS VEGAS</option>
                <option

                    data-sedan="3220"
                    data-suv="3320"
                    data-big_suv="3570"
                    data-van="3570"
                    data-pickup="4030"
                    data-motorcycle="2070"
                    value="199">NV-RENO</option>
                <option

                    data-sedan="2200"
                    data-suv="2300"
                    data-big_suv="2500"
                    data-van="2500"
                    data-pickup="2890"
                    data-motorcycle="1400"
                    value="200">NY-ALBANY</option>
                <option

                    data-sedan="2445"
                    data-suv="2545"
                    data-big_suv="2745"
                    data-van="2745"
                    data-pickup="3135"
                    data-motorcycle="1645"
                    value="201">NY-BUFFALO</option>
                <option

                    data-sedan="2165"
                    data-suv="2265"
                    data-big_suv="2465"
                    data-van="2465"
                    data-pickup="2855"
                    data-motorcycle="1365"
                    value="202">NY-LONG ISLAND</option>
                <option

                    data-sedan="2130"
                    data-suv="2230"
                    data-big_suv="2430"
                    data-van="2570"
                    data-pickup="2820"
                    data-motorcycle="1330"
                    value="203">NY-NEWBURGH</option>
                <option

                    data-sedan="2340"
                    data-suv="2440"
                    data-big_suv="2640"
                    data-van="2640"
                    data-pickup="3030"
                    data-motorcycle="1540"
                    value="204">NY-ROCHESTER</option>
                <option

                    data-sedan="2410"
                    data-suv="2510"
                    data-big_suv="2710"
                    data-van="2710"
                    data-pickup="3310"
                    data-motorcycle="1820"
                    value="205">NY-SYRACUSE</option>
                <option

                    data-sedan="2480"
                    data-suv="2580"
                    data-big_suv="2780"
                    data-van="2780"
                    data-pickup="3170"
                    data-motorcycle="1680"
                    value="212">OH- IAAI Stimmel Rd Sub lot</option>
                <option

                    data-sedan="2440"
                    data-suv="2540"
                    data-big_suv="2740"
                    data-van="2740"
                    data-pickup="3130"
                    data-motorcycle="1640"
                    value="213">OH- Vermillion North Ford Offsite lot</option>
                <option

                    data-sedan="2445"
                    data-suv="2545"
                    data-big_suv="2745"
                    data-van="2745"
                    data-pickup="3135"
                    data-motorcycle="1645"
                    value="206">OH-AKRON CANTON</option>
                <option

                    data-sedan="2410"
                    data-suv="2510"
                    data-big_suv="2710"
                    data-van="2710"
                    data-pickup="3100"
                    data-motorcycle="1610"
                    value="207">OH-CINCINNATI</option>
                <option

                    data-sedan="2515"
                    data-suv="2615"
                    data-big_suv="2815"
                    data-van="2815"
                    data-pickup="3205"
                    data-motorcycle="1715"
                    value="208">OH-CLEVELAND</option>
                <option

                    data-sedan="2515"
                    data-suv="2615"
                    data-big_suv="2815"
                    data-van="2815"
                    data-pickup="3205"
                    data-motorcycle="1715"
                    value="209">OH-CLEVELAND EAST</option>
                <option

                    data-sedan="2515"
                    data-suv="2615"
                    data-big_suv="2815"
                    data-van="2815"
                    data-pickup="3205"
                    data-motorcycle="1715"
                    value="210">OH-CLEVELAND WEST</option>
                <option

                    data-sedan="2480"
                    data-suv="2580"
                    data-big_suv="2780"
                    data-van="2780"
                    data-pickup="3170"
                    data-motorcycle="1680"
                    value="211">OH-COLUMBUS</option>
                <option

                    data-sedan="2550"
                    data-suv="2650"
                    data-big_suv="2850"
                    data-van="2850"
                    data-pickup="3240"
                    data-motorcycle="1750"
                    value="214">OH-DAYTON</option>
                <option

                    data-sedan="2425"
                    data-suv="2525"
                    data-big_suv="2775"
                    data-van="2775"
                    data-pickup="3115"
                    data-motorcycle="1435"
                    value="215">OK-OKLAHOMA CITY</option>
                <option

                    data-sedan="2440"
                    data-suv="2540"
                    data-big_suv="2790"
                    data-van="2790"
                    data-pickup="3130"
                    data-motorcycle="1590"
                    value="216">OK-TULSA</option>
                <option

                    data-sedan="3250"
                    data-suv="3350"
                    data-big_suv="3600"
                    data-van="3600"
                    data-pickup="4060"
                    data-motorcycle="2100"
                    value="217">OR-EUGENE</option>
                <option

                    data-sedan="3410"
                    data-suv="3510"
                    data-big_suv="3760"
                    data-van="3760"
                    data-pickup="4220"
                    data-motorcycle="2260"
                    value="218">OR-PORTLAND</option>
                <option

                    data-sedan="3290"
                    data-suv="3390"
                    data-big_suv="3640"
                    data-van="3640"
                    data-pickup="4100"
                    data-motorcycle="2140"
                    value="220">OR-PORTLAND NORTH</option>
                <option

                    data-sedan="3290"
                    data-suv="3390"
                    data-big_suv="3640"
                    data-van="3640"
                    data-pickup="4100"
                    data-motorcycle="2140"
                    value="221">OR-PORTLAND SOUTH</option>
                <option

                    data-sedan="3330"
                    data-suv="3430"
                    data-big_suv="3680"
                    data-van="3680"
                    data-pickup="4140"
                    data-motorcycle="2180"
                    value="219">OR-PORTLAND WEST</option>
                <option

                    data-sedan="3170"
                    data-suv="3270"
                    data-big_suv="3520"
                    data-van="3520"
                    data-pickup="3980"
                    data-motorcycle="2020"
                    value="222">OR-WOODBURN</option>
                <option

                    data-sedan="2375"
                    data-suv="2475"
                    data-big_suv="2675"
                    data-van="2675"
                    data-pickup="3065"
                    data-motorcycle="1575"
                    value="223">PA-ALTOONA</option>
                <option

                    data-sedan="2130"
                    data-suv="2230"
                    data-big_suv="2430"
                    data-van="2430"
                    data-pickup="2820"
                    data-motorcycle="1330"
                    value="224">PA-BRIDGEPORT</option>
                <option

                    data-sedan="2305"
                    data-suv="2405"
                    data-big_suv="2605"
                    data-van="2605"
                    data-pickup="2995"
                    data-motorcycle="1505"
                    value="225">PA-CHAMBERSBURG</option>
                <option

                    data-sedan="2515"
                    data-suv="2615"
                    data-big_suv="2815"
                    data-van="2815"
                    data-pickup="3205"
                    data-motorcycle="1715"
                    value="226">PA-ERIE</option>
                <option

                    data-sedan="2200"
                    data-suv="2300"
                    data-big_suv="2500"
                    data-van="2500"
                    data-pickup="2890"
                    data-motorcycle="1400"
                    value="227">PA-HARRISBURG</option>
                <option

                    data-sedan="2160"
                    data-suv="2260"
                    data-big_suv="2460"
                    data-van="2460"
                    data-pickup="2850"
                    data-motorcycle="1360"
                    value="228">PA-PHILADELPHIA</option>
                <option

                    data-sedan="2160"
                    data-suv="2260"
                    data-big_suv="2460"
                    data-van="2460"
                    data-pickup="2850"
                    data-motorcycle="1360"
                    value="229">PA-PHILADELPHIA EAST</option>
                <option

                    data-sedan="2480"
                    data-suv="2580"
                    data-big_suv="2780"
                    data-van="2780"
                    data-pickup="3170"
                    data-motorcycle="1680"
                    value="230">PA-PITTSBURG</option>
                <option

                    data-sedan="2480"
                    data-suv="2580"
                    data-big_suv="2780"
                    data-van="2780"
                    data-pickup="3170"
                    data-motorcycle="1680"
                    value="231">PA-PITTSBURG NORTH</option>
                <option

                    data-sedan="2445"
                    data-suv="2545"
                    data-big_suv="2745"
                    data-van="2745"
                    data-pickup="3135"
                    data-motorcycle="1645"
                    value="232">PA-PITTSBURG SOUTH</option>
                <option

                    data-sedan="2445"
                    data-suv="2545"
                    data-big_suv="2745"
                    data-van="2745"
                    data-pickup="3135"
                    data-motorcycle="1645"
                    value="233">PA-PITTSBURG WEST</option>
                <option

                    data-sedan="2235"
                    data-suv="2335"
                    data-big_suv="2535"
                    data-van="2535"
                    data-pickup="2925"
                    data-motorcycle="1435"
                    value="234">PA-SCRANTON</option>
                <option

                    data-sedan="2240"
                    data-suv="2340"
                    data-big_suv="2540"
                    data-van="2540"
                    data-pickup="2930"
                    data-motorcycle="1300"
                    value="235">PA-YORK HAVEN</option>
                <option

                    data-sedan="2165"
                    data-suv="2265"
                    data-big_suv="2465"
                    data-van="2465"
                    data-pickup="2855"
                    data-motorcycle="1225"
                    value="236">PA-YORKS SPRINGS</option>
                <option

                    data-sedan="2270"
                    data-suv="2370"
                    data-big_suv="2570"
                    data-van="2570"
                    data-pickup="2960"
                    data-motorcycle="1330"
                    value="237">RI-EXETER</option>
                <option

                    data-sedan="2340"
                    data-suv="2440"
                    data-big_suv="2640"
                    data-van="2640"
                    data-pickup="3030"
                    data-motorcycle="1540"
                    value="238">RI-PROVIDENCE</option>
                <option

                    data-sedan="2010"
                    data-suv="2110"
                    data-big_suv="2360"
                    data-van="2360"
                    data-pickup="2680"
                    data-motorcycle="1200"
                    value="239">SC-CHARLESTON</option>
                <option

                    data-sedan="2080"
                    data-suv="2180"
                    data-big_suv="2430"
                    data-van="2430"
                    data-pickup="2820"
                    data-motorcycle="1330"
                    value="240">SC-COLUMBIA</option>
                <option

                    data-sedan="2080"
                    data-suv="2180"
                    data-big_suv="2430"
                    data-van="2430"
                    data-pickup="2820"
                    data-motorcycle="1330"
                    value="241">SC-GREENVILLE</option>
                <option

                    data-sedan="2080"
                    data-suv="2180"
                    data-big_suv="2430"
                    data-van="2430"
                    data-pickup="2750"
                    data-motorcycle="1330"
                    value="242">SC-LEXINGTON</option>
                <option

                    data-sedan="2115"
                    data-suv="2215"
                    data-big_suv="2465"
                    data-van="2465"
                    data-pickup="2855"
                    data-motorcycle="1365"
                    value="243">SC-South Gaston Sublot</option>
                <option

                    data-sedan="2150"
                    data-suv="2250"
                    data-big_suv="2500"
                    data-van="2500"
                    data-pickup="2890"
                    data-motorcycle="1400"
                    value="245">SC-SPARTANBURG</option>
                <option

                    data-sedan="2150"
                    data-suv="2250"
                    data-big_suv="2500"
                    data-van="2500"
                    data-pickup="2890"
                    data-motorcycle="1300"
                    value="244">SC-Spartanburg sublot</option>
                <option

                    data-sedan="3210"
                    data-suv="3310"
                    data-big_suv="3510"
                    data-van="3510"
                    data-pickup="4080"
                    data-motorcycle="1930"
                    value="246">SD-SIOUX FALLS</option>
                <option

                    data-sedan="2330"
                    data-suv="2430"
                    data-big_suv="2680"
                    data-van="2680"
                    data-pickup="3070"
                    data-motorcycle="1580"
                    value="247">TN-CHATTANOOGA</option>
                <option

                    data-sedan="2430"
                    data-suv="2530"
                    data-big_suv="2780"
                    data-van="2780"
                    data-pickup="3170"
                    data-motorcycle="1680"
                    value="248">TN-KNOXVILLE</option>
                <option

                    data-sedan="2290"
                    data-suv="2390"
                    data-big_suv="2640"
                    data-van="2640"
                    data-pickup="3030"
                    data-motorcycle="1540"
                    value="249">TN-MEMPHIS</option>
                <option

                    data-sedan="2290"
                    data-suv="2390"
                    data-big_suv="2640"
                    data-van="2640"
                    data-pickup="3030"
                    data-motorcycle="1540"
                    value="250">TN-NASHVILLE</option>
                <option

                    data-sedan="2430"
                    data-suv="2530"
                    data-big_suv="2780"
                    data-van="2780"
                    data-pickup="3110"
                    data-motorcycle="1390"
                    value="251">TX-ABILENE</option>
                <option

                    data-sedan="2550"
                    data-suv="2650"
                    data-big_suv="2900"
                    data-van="2900"
                    data-pickup="3260"
                    data-motorcycle="1700"
                    value="252">TX-AMARILLO</option>
                <option

                    data-sedan="2510"
                    data-suv="2610"
                    data-big_suv="2860"
                    data-van="2860"
                    data-pickup="3220"
                    data-motorcycle="1520"
                    value="253">TX-ANDREWS</option>
                <option

                    data-sedan="2310"
                    data-suv="2410"
                    data-big_suv="2660"
                    data-van="2660"
                    data-pickup="3020"
                    data-motorcycle="1460"
                    value="254">TX-AUSTIN</option>
                <option

                    data-sedan="2310"
                    data-suv="2410"
                    data-big_suv="2660"
                    data-van="2660"
                    data-pickup="3020"
                    data-motorcycle="1460"
                    value="255">TX-CORPUS CHRISTI</option>
                <option

                    data-sedan="2310"
                    data-suv="2410"
                    data-big_suv="2660"
                    data-van="2660"
                    data-pickup="3020"
                    data-motorcycle="1460"
                    value="256">TX-CRASHEDTOYS DALLAS</option>
                <option

                    data-sedan="2310"
                    data-suv="2410"
                    data-big_suv="2660"
                    data-van="2660"
                    data-pickup="3020"
                    data-motorcycle="1460"
                    value="257">TX-DALLAS COPART</option>
                <option

                    data-sedan="2310"
                    data-suv="2410"
                    data-big_suv="2660"
                    data-van="2660"
                    data-pickup="3020"
                    data-motorcycle="1460"
                    value="258">TX-DALLAS SOUTH</option>
                <option

                    data-sedan="2510"
                    data-suv="2610"
                    data-big_suv="2860"
                    data-van="2860"
                    data-pickup="3140"
                    data-motorcycle="1420"
                    value="259">TX-EL PASO</option>
                <option

                    data-sedan="2390"
                    data-suv="2490"
                    data-big_suv="2740"
                    data-van="2740"
                    data-pickup="3100"
                    data-motorcycle="1540"
                    value="260">TX-FT. WORTH</option>
                <option

                    data-sedan="2110"
                    data-suv="2210"
                    data-big_suv="2460"
                    data-van="2460"
                    data-pickup="2820"
                    data-motorcycle="1260"
                    value="261">TX-HOUSTON</option>
                <option

                    data-sedan="2310"
                    data-suv="2410"
                    data-big_suv="2660"
                    data-van="2660"
                    data-pickup="3020"
                    data-motorcycle="1460"
                    value="262">TX-LONGVIEW</option>
                <option

                    data-sedan="2590"
                    data-suv="2690"
                    data-big_suv="2940"
                    data-van="2940"
                    data-pickup="3170"
                    data-motorcycle="1450"
                    value="263">TX-LUBBOCK</option>
                <option

                    data-sedan="2310"
                    data-suv="2410"
                    data-big_suv="2660"
                    data-van="2660"
                    data-pickup="3020"
                    data-motorcycle="1460"
                    value="264">TX-LUFKIN</option>
                <option

                    data-sedan="2310"
                    data-suv="2410"
                    data-big_suv="2660"
                    data-van="2660"
                    data-pickup="3020"
                    data-motorcycle="1460"
                    value="265">TX-MCALLEN</option>
                <option

                    data-sedan="2430"
                    data-suv="2530"
                    data-big_suv="2780"
                    data-van="2780"
                    data-pickup="3140"
                    data-motorcycle="1420"
                    value="266">TX-PERMIAN BASIN</option>
                <option

                    data-sedan="2390"
                    data-suv="2490"
                    data-big_suv="2740"
                    data-van="2740"
                    data-pickup="3100"
                    data-motorcycle="1540"
                    value="267">TX-SAN ANTONIO</option>
                <option

                    data-sedan="2310"
                    data-suv="2410"
                    data-big_suv="2660"
                    data-van="2660"
                    data-pickup="3020"
                    data-motorcycle="1460"
                    value="268">TX-WACO</option>
                <option

                    data-sedan="3210"
                    data-suv="3310"
                    data-big_suv="3560"
                    data-van="3560"
                    data-pickup="4020"
                    data-motorcycle="2060"
                    value="269">UT-OGDEN</option>
                <option

                    data-sedan="3210"
                    data-suv="3310"
                    data-big_suv="3560"
                    data-van="3560"
                    data-pickup="4020"
                    data-motorcycle="2060"
                    value="270">UT-SALT LAKE CITY</option>
                <option

                    data-sedan="2270"
                    data-suv="2370"
                    data-big_suv="2570"
                    data-van="2570"
                    data-pickup="2960"
                    data-motorcycle="1330"
                    value="271">VA-CULPEPER</option>
                <option

                    data-sedan="2410"
                    data-suv="2510"
                    data-big_suv="2710"
                    data-van="2710"
                    data-pickup="3100"
                    data-motorcycle="1610"
                    value="272">VA-DANVILLE</option>
                <option

                    data-sedan="2270"
                    data-suv="2370"
                    data-big_suv="2570"
                    data-van="2570"
                    data-pickup="2960"
                    data-motorcycle="1470"
                    value="273">VA-FREDERICKSBURG</option>
                <option

                    data-sedan="2270"
                    data-suv="2370"
                    data-big_suv="2570"
                    data-van="2570"
                    data-pickup="2960"
                    data-motorcycle="1470"
                    value="274">VA-FREDERICKSBURG-SOUTH</option>
                <option

                    data-sedan="2375"
                    data-suv="2475"
                    data-big_suv="2675"
                    data-van="2675"
                    data-pickup="3065"
                    data-motorcycle="1575"
                    value="275">VA-HAMPTON</option>
                <option

                    data-sedan="2270"
                    data-suv="2370"
                    data-big_suv="2570"
                    data-van="2570"
                    data-pickup="2960"
                    data-motorcycle="1330"
                    value="276">VA-NORTHERN VIRGINIA</option>
                <option

                    data-sedan="2515"
                    data-suv="2615"
                    data-big_suv="2815"
                    data-van="2815"
                    data-pickup="3205"
                    data-motorcycle="1715"
                    value="277">VA-PULASKI</option>
                <option

                    data-sedan="2340"
                    data-suv="2440"
                    data-big_suv="2640"
                    data-van="2640"
                    data-pickup="3030"
                    data-motorcycle="1540"
                    value="278">VA-RICHMOND</option>
                <option

                    data-sedan="2340"
                    data-suv="2440"
                    data-big_suv="2640"
                    data-van="2640"
                    data-pickup="3030"
                    data-motorcycle="1540"
                    value="279">VA-ROANOKE</option>
                <option

                    data-sedan="2325"
                    data-suv="2425"
                    data-big_suv="2625"
                    data-van="2625"
                    data-pickup="3015"
                    data-motorcycle="1385"
                    value="280">VA-SUFFOLK</option>
                <option

                    data-sedan="2340"
                    data-suv="2440"
                    data-big_suv="2640"
                    data-van="2640"
                    data-pickup="3030"
                    data-motorcycle="1540"
                    value="281">VA-TIDEWATER</option>
                <option

                    data-sedan="2550"
                    data-suv="2650"
                    data-big_suv="2850"
                    data-van="2850"
                    data-pickup="3240"
                    data-motorcycle="1750"
                    value="285">VT- Devil&#039;s Bowl Sub lot</option>
                <option

                    data-sedan="2620"
                    data-suv="2720"
                    data-big_suv="2920"
                    data-van="2920"
                    data-pickup="3310"
                    data-motorcycle="1820"
                    value="282">VT-BURLINGTON</option>
                <option

                    data-sedan="2550"
                    data-suv="2650"
                    data-big_suv="2850"
                    data-van="2850"
                    data-pickup="3380"
                    data-motorcycle="1470"
                    value="283">VT-FAIR HAVEN</option>
                <option

                    data-sedan="2550"
                    data-suv="2650"
                    data-big_suv="2850"
                    data-van="2850"
                    data-pickup="3380"
                    data-motorcycle="1470"
                    value="284">VT-ORLEANS</option>
                <option

                    data-sedan="2550"
                    data-suv="2650"
                    data-big_suv="2850"
                    data-van="2850"
                    data-pickup="3380"
                    data-motorcycle="1470"
                    value="286">VT-RUTLAND</option>
                <option

                    data-sedan="3530"
                    data-suv="3630"
                    data-big_suv="3880"
                    data-van="3880"
                    data-pickup="4030"
                    data-motorcycle="2380"
                    value="290">WA- Seattle Puyallup</option>
                <option

                    data-sedan="3410"
                    data-suv="3510"
                    data-big_suv="3760"
                    data-van="3760"
                    data-pickup="4220"
                    data-motorcycle="2260"
                    value="289">WA-GRAHAM</option>
                <option

                    data-sedan="3450"
                    data-suv="3550"
                    data-big_suv="3800"
                    data-van="3800"
                    data-pickup="4260"
                    data-motorcycle="2300"
                    value="291">WA-NORTH SEATTLE</option>
                <option

                    data-sedan="3570"
                    data-suv="3670"
                    data-big_suv="3920"
                    data-van="3920"
                    data-pickup="4380"
                    data-motorcycle="2420"
                    value="292">WA-PASCO</option>
                <option

                    data-sedan="3280"
                    data-suv="3380"
                    data-big_suv="3630"
                    data-van="3630"
                    data-pickup="4250"
                    data-motorcycle="1810"
                    value="287">WA-SEATTLE</option>
                <option

                    data-sedan="3330"
                    data-suv="3430"
                    data-big_suv="3680"
                    data-van="3680"
                    data-pickup="4140"
                    data-motorcycle="2180"
                    value="288">WA-SEATTLE-SOUTH</option>
                <option

                    data-sedan="3650"
                    data-suv="3750"
                    data-big_suv="4000"
                    data-van="4000"
                    data-pickup="4460"
                    data-motorcycle="2500"
                    value="293">WA-SPOKANE</option>
                <option

                    data-sedan="2875"
                    data-suv="2975"
                    data-big_suv="3175"
                    data-van="3175"
                    data-pickup="3565"
                    data-motorcycle="2075"
                    value="294">WI-APPLETON</option>
                <option

                    data-sedan="2805"
                    data-suv="2905"
                    data-big_suv="3105"
                    data-van="3105"
                    data-pickup="3360"
                    data-motorcycle="1890"
                    value="295">WI-MADISON</option>
                <option

                    data-sedan="2770"
                    data-suv="2870"
                    data-big_suv="3070"
                    data-van="3070"
                    data-pickup="3460"
                    data-motorcycle="1970"
                    value="296">WI-MILWAUKEE</option>
                <option

                    data-sedan="2840"
                    data-suv="2940"
                    data-big_suv="3140"
                    data-van="3140"
                    data-pickup="3530"
                    data-motorcycle="2040"
                    value="297">WI-PORTAGE</option>
                <option

                    data-sedan="2520"
                    data-suv="2620"
                    data-big_suv="2820"
                    data-van="2820"
                    data-pickup="3350"
                    data-motorcycle="1440"
                    value="298">WV-BUCKHANNON</option>
                <option

                    data-sedan="2480"
                    data-suv="2580"
                    data-big_suv="2780"
                    data-van="2780"
                    data-pickup="3170"
                    data-motorcycle="1540"
                    value="299">WV-CHARLESTON</option>
                <option

                    data-sedan="2620"
                    data-suv="2720"
                    data-big_suv="2920"
                    data-van="2920"
                    data-pickup="3310"
                    data-motorcycle="1820"
                    value="300">WV-SHADY SPRING</option>
                <option

                    data-sedan="3830"
                    data-suv="3930"
                    data-big_suv="4130"
                    data-van="4130"
                    data-pickup="4540"
                    data-motorcycle="3030"
                    value="301">WY-CASPER</option>
            </select>
  )
}
