import React, { useEffect, useState } from 'react'
import { HighGroundClearanceWrapper } from '../style'
import { useDispatch, useSelector } from 'react-redux'
import { carDataSlice } from '../../../store/slices/carDataSlice';
export const HighGroundClearanceInput = () => {
    const { carAge, vehicleType, engineType } = useSelector((state) => state.carData);
    const { setHighGroundClearance } = carDataSlice.actions;
    const dispatch = useDispatch()
    const [isShow, setIsShow] = useState(false)
    const [isChecked, setIsChecked] = useState(false);
    const currentYear = new Date().getFullYear();
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };
    useEffect(() => {
        if (carAge >= currentYear - 3 && vehicleType && vehicleType !== 'motorcycle' && vehicleType !== 'pickup' && engineType === 'gasoline') {
            setIsShow(true)
        } else {
            setIsShow(false)
        }
    }, [carAge, vehicleType, engineType, currentYear])

    useEffect(() => {
        if (isShow) {
            dispatch(setHighGroundClearance(isChecked))
        } else {
            dispatch(setHighGroundClearance(false))
        }
    }, [isChecked, dispatch, setHighGroundClearance, isShow])

    return (
        <HighGroundClearanceWrapper style={{ display: isShow ? 'flex' : 'none' }}>
            <label htmlFor="hight_ground_clearance">Բարձր անցունակությամբ</label>
            <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
        </HighGroundClearanceWrapper>
    )
}
