import axios from 'axios';
// import dotenv from 'dotenv';

// dotenv.config();

// const API_KEY = 'ff941bf40ac0907c92d62b0b';

export const fetchExchangeRate = async (from, to) => {
    const API_KEY = process.env.REACT_APP_EXCHANGE_RATE_API_KEY;
    try {
      const response = await axios.get(`https://v6.exchangerate-api.com/v6/${API_KEY}/latest/${from}`);
      const data = response.data;
      return data.conversion_rates[to];
    } catch (error) {
      console.error('Error fetching the exchange rate:', error);
      return null;
    }
  };