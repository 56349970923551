// src/features/counter/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const AuctionDataSlice = createSlice({
  name: 'auctionData',
  initialState: {
    selectedAuction: 'copart',
    vehiclePrice: 0,
    auctionFee: 0,
    shippingPrice: {
      sedan: 0,
      suv: 0,
      bigSuv: 0,
      van: 0,
      pickup: 0,
      motorcycle: 0
    },
    currentShippingPrice:0
  },
  reducers: {
    setSelectedAuction: (state, action) => {
      state.selectedAuction = action.payload;
    },
    setVehiclePrice: (state, action) => {
      state.vehiclePrice = action.payload;
    },
    setAuctionFee: (state, action) => {
      state.auctionFee = action.payload;
    },
    setShippingPrice: (state, action) => {
      state.shippingPrice = action.payload;
    },
    setCurrentShippingPrice: (state, action) => {
      state.currentShippingPrice = action.payload;
    },
  },
});

// Export the actions generated by createSlice
export const { setSelectedAuction, setVehiclePrice, setAuctionFee, setShippingPrice,setCurrentShippingPrice } = AuctionDataSlice.actions;

// Export the reducer to be included in the store
export default AuctionDataSlice.reducer;
