import styled from "styled-components";

export const ResultWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    backdrop-filter: blur(10px);
    /* background: #eaeaea; */
    padding-bottom: 50px;

    &.animated_height_open {
        animation: heightAnimationOpen 0.3s ease-in-out forwards;
    }

    &.animated_height_close {
        animation: heightAnimationClose 0.3s ease-in-out forwards;
    }

    /* Keyframes for height animation */
    @keyframes heightAnimationOpen {
        0% {
            height: 0;
        }
        100% {
            height: auto;
        }
    }
    @keyframes heightAnimationClose {
        0% {
            height: auto;
        }
        100% {
            height: 0;
        }
    }
`
export const SeparatelyResult = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
    @media screen and (max-width: 600px) {
        width: 100%;
    }

    .result_wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .result {
        width: 92%;
        border-radius: 20px;
        justify-content: space-between;
        background-color: #f5f5f5;
        padding: 20px;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 10px;
        @media screen and (max-width: 780px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @media screen and (max-width: 520px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    & div + p {
        color: white
    }
`
export const VehiclePrice = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 5px;
    background: #efefef;
    border-radius: 10px;
    height: 50px;
    align-items: center;
`
export const AuctionFee = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 5px;
    background: #efefef;
    border-radius: 10px;
    height: 50px;
    align-items: center;
`
export const ShippingPrice = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 5px;
    background: #efefef;
    border-radius: 10px;
    height: 50px;
    align-items: center;
`
export const CustomsValue = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 5px;
    background: #efefef;
    border-radius: 10px;
    height: 50px;
    align-items: center;
`
export const CustomsDuty = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 5px;
    background: #efefef;
    border-radius: 10px;
    height: 50px;
    align-items: center;
`
export const Insurance = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 5px;
    background: #efefef;
    border-radius: 10px;
    height: 50px;
    align-items: center;
`
export const VATWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 5px;
    background: #efefef;
    border-radius: 10px;
    height: 50px;
    align-items: center;
`
export const EcoTax = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 5px;
    background: #efefef;
    border-radius: 10px;
    height: 50px;
    align-items: center;
`
export const OtherTaxes = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 5px;
    background: #efefef;
    border-radius: 10px;
    height: 50px;
    align-items: center;
`

export const TotalResult = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
    background-color: #c4f5ad;
    color: #1d3a87;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 700;
    padding: 10px;
    margin-top: 20px;

    //& p {
    //    padding: 5px;
    //    background: #f8e8d2;
    //}
`