import React from 'react'
import { FooterWrapper, Map, Sites, TikTok, Instagram, Facebook, Title, Contacts } from './style'
import Tik_tok from '../../assets/tik-tok.png'
import instagram from '../../assets/instagram.png'
import facebook from '../../assets/facebook.png'
export const Footer = () => {
    return (
        <FooterWrapper>
            <Map>
                <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3047.8246234298385!2d44.518554069339444!3d40.19071622727992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDDCsDExJzI4LjQiTiA0NMKwMzEnMTQuMCJF!5e0!3m2!1sru!2sam!4v1721844338106!5m2!1sru!2sam" width="100%" title='google map' height="250" loading="lazy"></iframe>
            </Map>
            <Contacts>
                <Title>Կապ մեզ հետ</Title>
                <div className='contacts_content'>
                    <a href="mailto: 4hmotorsarm4@gmail.com" rel="noreferrer" target='_blank'>4hmotorsarm4@gmail.com</a>
                    <a href='tel: 093169316' >+(374)93 169316</a>
                    <a href='tel: 044341644' >+(374)44 341644</a>
                </div>
            </Contacts>
            <Sites>
                <Title>Սոցիալական մեդիա</Title>
                <div className='sites_content'>
                    <TikTok href='https://www.tiktok.com/@4hmotorsyerevan' target='_blank'>
                        <img src={Tik_tok} alt="tik tok" />
                        <p>Tik-tok</p>
                    </TikTok>
                    <Instagram href='https://www.instagram.com/4h_motors/?igsh=MWZqeWI1ZzRudnFnNg%3D%3D&utm_source=qr' target='_blank'>
                        <img src={instagram} alt="instagram" />
                        <p>Instagram</p>
                    </Instagram>
                    <Facebook href='https://www.facebook.com/4H.Motors' target='_blank' >
                        <img src={facebook} alt="facebook" />
                        <p>Facebook</p>
                    </Facebook>
                </div>
            </Sites>

        </FooterWrapper>
    )
}
