import React,{useState,useEffect} from 'react'
import { VehicleTypeWrapper } from '../style'
import { carDataSlice } from '../../../store/slices/carDataSlice';
import { useDispatch } from 'react-redux';
export const VehicleTypeInput = () => {

    const { setVehicleType } = carDataSlice.actions
    const dispatch = useDispatch()
    const [vehicleTypeState, setVehicleTypeState] = useState('')
  
    const handleSelectVehicleType = (e) => {
      if (e.target.value) {
        setVehicleTypeState(e.target.value)
      } else { setVehicleTypeState(null) }
    }
  
    useEffect(() => {
      dispatch(setVehicleType(vehicleTypeState))
    }, [vehicleTypeState, setVehicleType, dispatch])
  return (
    <VehicleTypeWrapper>
        <label htmlFor="vehicle_type">Տրանսպորտային միջոցի տեսակը</label>
        <select name="vehicle_type" id="vehicle_type" onChange={handleSelectVehicleType} required>
          <option style={{ display: 'none' }} value=""></option>
          <option value="sedan">Սեդան</option>
          <option value="motorcycle">Մոտոցիկլ</option>
          <option value="pickup">Պիկապ</option>
          <option value="suv">Ամենագնաց</option>
          <option value="bigSuv">Մեծ ամենագնաց</option>
          <option value="minivan">Մինիվեն</option>
        </select>
      </VehicleTypeWrapper>
  )
}
