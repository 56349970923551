import React, { useState, useEffect } from 'react'
import { EngineTypeWrapper } from '../style'
import { carDataSlice } from '../../../store/slices/carDataSlice';
import { useDispatch, useSelector } from 'react-redux';
export const EngineTypeInput = () => {
  const { vehicleType } = useSelector((state) => state.carData)
  const { setEngineType } = carDataSlice.actions
  const dispatch = useDispatch()
  const [engineTypeState, setEngineTypeState] = useState('')
  const [vehicleTypeState, setVehicleTypeState] = useState(false)
  const handleSelectEngineType = (e) => {
    if (e.target.value) {
      setEngineTypeState(e.target.value)
    } else { setEngineTypeState(null) }
  }
  useEffect(() => {
    if (vehicleType === 'motorcycle') {
      setVehicleTypeState(true)
      setEngineTypeState('gasoline')
    } else {
      setVehicleTypeState(false)
    }
  }, [vehicleType])
  useEffect(() => {
    dispatch(setEngineType(engineTypeState))
  }, [engineTypeState, setEngineType, dispatch])
  return (
    <EngineTypeWrapper>
      <label htmlFor="fuel_type">Շարժիչի Տեսակ</label>
      <select name="fuel_type" id="fuel_type" onChange={handleSelectEngineType} value={vehicleTypeState ? 'gasoline' : engineTypeState} required>
        <option style={{ display: 'none' }} value=""></option>
        <option value="gasoline">Բենզին</option>
        <option disabled={vehicleTypeState} value="hybrid">Հիբրիդ</option>
        <option disabled={vehicleTypeState} value="fullElectric">Էլեկտրական</option>
        <option disabled={vehicleTypeState} value="diesel">Դիզել</option>
      </select>
    </EngineTypeWrapper>
  )
}
