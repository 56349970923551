import styled from "styled-components";

export const FooterWrapper = styled.div`
    width: 100%;
    background: #3d3d3b;
    height: 300px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: flex-start;
    position: relative;
    padding-top: 20px;
    @media screen and (max-width: 780px) {
        & {
            flex-direction: column-reverse;
            height: auto;
            align-items: center;
            gap: 20px;
            padding: 20px;
        }
    }
    & img {
        width: 24px;
        height: 24px;
    }
`
export const Map = styled.div`
    width: 30%;
    margin-right: 25px;
    @media screen and (max-width: 780px) {
        & {
            width: 80%;
            margin: 20px auto;
        }
    }
    
`
export const Contacts = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    justify-content: flex-start;
    & .contacts_content{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    & a {
        display: flex;
        align-items: center;
        gap: 6px;
        color: #ebebeb;
        text-decoration: none;
        font-size: 16px;
    }
    @media screen and (max-width: 780px) {
        &{
            align-items: center;
        }
        & .contacts_content{
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }
        & .contacts_content a:first-child{
            width: 100%;
            text-align: center;
            display: block;
        }
    }
`
export const Sites = styled.div`
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    & .sites_content{
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
& a{
    display: flex;
    align-items: center;
    gap: 6px;
    color: #ebebeb;
    text-decoration: none;
    font-size: 16px;
}
@media screen and (max-width: 780px) {
    &{
        margin: 0;
    align-items: center;
    }
    & .sites_content{
        flex-direction: row;
    align-items: center;
    gap: 70px;
    margin-bottom: 30px;
    }
        & .sites_content p{
            display: none;
        }
    }
`
export const Title = styled.div`
    color: #ebebeb;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid;
    width: 200px;
    @media screen and (max-width: 780px) {
        & {
            border-bottom: 0;
            border-top: 1px solid;
            text-align: center;
        }
    }
    
`
export const TikTok = styled.a``
export const Instagram = styled.a``
export const Facebook = styled.a``