import { configureStore } from '@reduxjs/toolkit';
// import AuctionDataSlice from '../features/counter/AuctionDataSlice';
import AuctionDataSlice from './slices/auctionDataSlice'
import carDataSlice from './slices/carDataSlice';
import customsValuesSlice from './slices/customsValuesSlice';
import rateSlice from './slices/rateSlice';
import locationDataSlice from './slices/locationDataSlice';
export const store = configureStore({
  reducer: {
    auctionData: AuctionDataSlice,
    carData:carDataSlice,
    customsValue:customsValuesSlice,
    rate:rateSlice,
    locationData:locationDataSlice,
  },
});