import styled from "styled-components";

export const CalculatorWrapper = styled.div`
    & .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        backdrop-filter: blur(10px);
        /* background: #eaeaea; */
    }
`
export const FormWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;

    & .border {
        height: 400px;
        width: 1px;
        background: black;
    }

`