import React, { useEffect, useState } from 'react'
import { AgeWrapper } from '../style'
import { carDataSlice } from '../../../store/slices/carDataSlice'
import { useDispatch } from 'react-redux'
export const AgeInput = () => {
    const years = {
        toThreeYears: 'մինչև 3 տարի',
        toFiveYears: '3 - 5 տարի',
        toSevenYears: '5 - 7 տարի',
        afterSevenYears: '7+ տարի'
    }
    const { setCarAge } = carDataSlice.actions
    const dispatch = useDispatch()
    const [age, setAge] = useState('')
    const handleSelectAge = (e) => {
        if (e.target.value) {
            setAge(e.target.value)
        } else { setAge(null) }
    }

    useEffect(() => {
        const intAge = parseInt(age)
        dispatch(setCarAge(intAge))
    }, [age, setCarAge, dispatch])

    function getYearsArray(startYear) {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = currentYear; year >= startYear; year--) {
            years.push(year);
        }
        return years;
    }
    const yearsArray = getYearsArray(1970);
    const handleYearChecker = (year)=>{
        const currentYear = new Date().getFullYear();
        if (year >= currentYear - 3) {
           return years.toThreeYears
        } else if (year >= currentYear - 5 && year < currentYear - 3) {
            return years.toFiveYears
        } else if (year >= currentYear - 7 && year < currentYear - 5) {
            return years.toSevenYears
        } else {
            return years.afterSevenYears
        }
    }
    return (
        <AgeWrapper>
            <label htmlFor="age">Տարիք</label>
            <select name="age" id="age" onChange={handleSelectAge} required>
                <option value='' style={{ display: 'none' }}></option>
                {
                    yearsArray.map((year) =>
                        <option key={year} value={year}>{year} <span className='tooltip'>'{handleYearChecker(year)}'</span> </option>
                    )
                }
            </select>
        </AgeWrapper>
    )
}
