// src/features/counter/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const locationDataSlice = createSlice({
    name: 'locationData',
    initialState: {
        locations: null
    },
    reducers: {
        setLocationData: (state, action) => {
            state.locations = action.payload;
        },
    },
});

// Export the actions generated by createSlice
export const { setLocationData } = locationDataSlice.actions;

// Export the reducer to be included in the store
export default locationDataSlice.reducer;
