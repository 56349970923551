import React from 'react'
import { CarFormWrapper } from './style'
import { AgeInput } from './inputs/AgeInput'
import { EngineInput } from './inputs/EngineInput'
import { EngineTypeInput } from './inputs/EngineTypeInput'
import { VehicleTypeInput } from './inputs/VehicleTypeInput'
import { HighGroundClearanceInput } from './inputs/HighGroundClearanceCheckbox'
import { TonnageInput } from './inputs/TonnageInput'
export const CarDataForm = () => {
  return (
    <CarFormWrapper>
      <AgeInput />
      <div className="engine_and_cube">
        <EngineTypeInput />
        <EngineInput />
      </div>
      <div className="vehicle_and_tonnage">
        <VehicleTypeInput />
        <TonnageInput />
      </div>
      <HighGroundClearanceInput />
    </CarFormWrapper>
  )
}
