// src/features/counter/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const customsValuesSlice = createSlice({
  name: 'customsValue',
  initialState: {
    customsValue: 0,
    customsDuty: 0,
    insurance: 0,
    VAT: 0,
    ecoTax: 0,
    otherTaxes: 0,
    total: 0,
    readyToShow: false,
    readyToCalculate: false
  },
  reducers: {
    setCustomsValue: (state, action) => {
      state.customsValue = action.payload;
    },
    setCustomsDuty: (state, action) => {
      state.customsDuty = action.payload;
    },
    setInsurance: (state, action) => {
      state.insurance = action.payload;
    },
    setVAT: (state, action) => {
      state.VAT = action.payload;
    },
    setEcoTax: (state, action) => {
      state.ecoTax = action.payload;
    },
    setOtherTaxes: (state, action) => {
      state.otherTaxes = action.payload;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
    },
    setReadyToShow: (state, action) => {
      state.readyToShow = action.payload;
    },
    setReadyToCalculate: (state, action) => {
      state.readyToCalculate = action.payload;
    },
  },
});

// Export the actions generated by createSlice
export const { setCustomsDuty, setVAT, setEcoTax, setTotal, setCustomsValue, setInsurance, setOtherTaxes, setReadyToShow, setReadyToCalculate } = customsValuesSlice.actions;

// Export the reducer to be included in the store
export default customsValuesSlice.reducer;
