import React, {useEffect, useState} from 'react'
import copartLogo from '../../assets/copart-logo.png'
import iaaLogo from '../../assets/iaa-logo.png'
import {LogoWrapper} from './style'
import {AuctionDataSlice} from '../../store/slices/auctionDataSlice'
import {useDispatch} from 'react-redux'

export const AuctionList = () => {
    const {setSelectedAuction} = AuctionDataSlice.actions
    const [auction, setAuction] = useState('copart')
    const dispatch = useDispatch()
    const handleSelectAuction = (e) => {
        if (e.target) {
            setAuction(e.target.id)

        }
    }
    useEffect(() => {
        dispatch(setSelectedAuction(auction))
    }, [auction, setSelectedAuction, dispatch])

    return (
        <LogoWrapper>
            <div className={`copart_logo ${auction === 'copart' ? 'active' : ''}`} onClick={handleSelectAuction}>
                <img src={copartLogo} alt="Copart" id='copart'/>
            </div>
            <div className={`IAA_logo ${auction === 'iaai' ? 'active' : ''}`} onClick={handleSelectAuction}>
                <img src={iaaLogo} alt="Iaa" id='iaai'/>
            </div>
        </LogoWrapper>
    )
}
