import styled from "styled-components";

export const SelectWrapper = styled.div`
    width: 99%;

    .custom-datalist {
        position: relative;
    }

    .custom-datalist input {
    }

    .custom-datalist .options-list {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        max-height: 230px;
        overflow-y: auto;
        border: 1px solid #ccc;
        border-top: none;
        background-color: white;
        z-index: 1000;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 4px 4px;
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .custom-datalist .options-list p {
        padding: 4px;
        cursor: pointer;
    }

    .custom-datalist .options-list p:hover {
        background-color: #f0f0f0;
    }

`

 
