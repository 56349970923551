import React from 'react'
import {useSelector} from 'react-redux'
import {
    ResultWrapper,
    SeparatelyResult,
    VehiclePrice,
    AuctionFee,
    ShippingPrice,
    CustomsValue,
    CustomsDuty,
    Insurance,
    VATWrapper,
    EcoTax,
    TotalResult,
    OtherTaxes
} from './style'

export const CalculatedResult = () => {
    const {vehiclePrice, currentShippingPrice, auctionFee} = useSelector((state) => state.auctionData)
    const {
        customsDuty,
        VAT,
        ecoTax,
        total,
        insurance,
        otherTaxes,
        readyToShow
    } = useSelector((state) => state.customsValue)
    const {amd} = useSelector((state) => state.rate)
    return (
        <ResultWrapper className={readyToShow ? 'animated_height_open' : 'animated_height_close'}>
            <SeparatelyResult>
                <div className="result_wrapper">
                    <div className="result">
                        <VehiclePrice>
                            <p>Մեքենայի Արժեք</p>
                            <p>{vehiclePrice}</p>
                        </VehiclePrice>
                        <AuctionFee>
                            <p>Աճուրդի միջնորդավճար</p>
                            <p>{auctionFee}</p>
                        </AuctionFee>
                        <ShippingPrice>
                            <p>Տեղափոխման վճար</p>
                            <p>{currentShippingPrice}</p>
                        </ShippingPrice>
                        <Insurance>
                            <p>Ապահովագրություն</p>
                            <p>{insurance}</p>
                        </Insurance>
                        <CustomsValue>
                            <p>Միջնորդավճար</p>
                            <p>{300}</p>
                        </CustomsValue>
                        <CustomsDuty>
                            <p>Մաքսատուրք</p>
                            <p>{customsDuty}</p>
                        </CustomsDuty>
                        <VATWrapper>
                            <p>ԱԱհ</p>
                            <p>{VAT}</p>
                        </VATWrapper>
                        <EcoTax>
                            <p>Բնապահպանական հարկ</p>
                            <p>{ecoTax}</p>
                        </EcoTax>
                        <OtherTaxes>
                            <p>Այլ հարկեր</p>
                            <p>{otherTaxes}</p>
                        </OtherTaxes>
                    </div>
                </div>
            </SeparatelyResult>
            <TotalResult>
                <p>Ընդհանուր</p>
                <p>{total} $</p>
                <p>{Math.round(total * amd)} ֏</p>
            </TotalResult>
        </ResultWrapper>

    )
}
