// src/features/counter/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const rateSlice = createSlice({
    name: 'rate',
    initialState: {
        amd: 0,
        eur: 0,
        usd: 0
    },
    reducers: {
        setAMD: (state, action) => {
            state.amd = action.payload;
        },
        setEUR: (state, action) => {
            state.eur = action.payload;
        },
        setUSD: (state, action) => {
            state.usd = action.payload;
        },
    },
});

// Export the actions generated by createSlice
export const { setAMD,setEUR,setUSD } = rateSlice.actions;

// Export the reducer to be included in the store
export default rateSlice.reducer;
