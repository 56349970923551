import React from 'react'
import styled from 'styled-components'

const ButtonWrapper = styled.input.attrs({
    type: 'submit',
    value: 'Հաշվել'
})`
    padding: 8px 50px;
    width: 200px;
    height: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    background-color: #577bd9;
    color: #ffffff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
;

    &:hover {
        background: #3e6de1;
    }
`
const WrapButton = styled.div`
    width: 97%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
`
export const Button = () => {
    return (
        <WrapButton>
        <ButtonWrapper className='hover_effect'/>
        </WrapButton>
    )
}
