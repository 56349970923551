import styled from "styled-components";

export const HeaderWrapper = styled.div`
    width: 100%;
    background: #3d3d3b;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 780px) {
        & .logo img{
            height: 50px;
        }
        & .date_wrapper p{
            font-size: 16px;
        }
        & .logo{
            padding: 10px;
        }
        & .date_wrapper{
            margin-right: 10px;
        }
    }
`
export const Logo = styled.div`
padding: 10px 40px;
 & img {
    height: 80px;
 }
`
export const FullDateWrapper = styled.div`
    margin-right: 50px;
& p {
    color: #ebebeb;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;

}
& span {
    color: #ebebeb;
    font-size: 14px;
}
`