import styled from "styled-components";

export const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    /* background: #eaeaea; */

    & .copart_logo, .IAA_logo {
        width: 80px;
        height: 80px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        border-bottom: 5px solid transparent;
        transition: 0.3s ease-in-out;
        &.active{
            transition: 0.3s ease-in-out;
            border-bottom: 5px solid #577bd9;   
        }
    }

    & img {
        width: 100%;
    }
`