import React, { useState, useEffect } from 'react'
import { EngineWrapper } from '../style'
import { carDataSlice } from '../../../store/slices/carDataSlice';
import { useDispatch, useSelector } from 'react-redux';
export const EngineInput = () => {
  const { engineType } = useSelector((state) => state.carData)
  const { setEngineVolume } = carDataSlice.actions;
  const dispatch = useDispatch();
  const [engineVolumeState, setEngineVolumeState] = useState('');
  const [formState, setFormState] = useState({
    isRequired: true,
    isDisabled: false
  })
  useEffect(() => {
    if (engineType === 'fullElectric') {
      setFormState({
        isRequired: false,
        isDisabled: true
      })
      dispatch(setEngineVolume(null))
    } else {
      setFormState({
        isRequired: true,
        isDisabled: false
      })

    }
    // eslint-disable-next-line
  }, [engineType])
  const handleSelectEngineVolume = (e) => {
    const value = e.target.value;
    if (value) {
      const floatValue = parseFloat(value);
      setEngineVolumeState(floatValue);
    } else {
      setEngineVolumeState('');
    }
  };

  useEffect(() => {
    dispatch(setEngineVolume(engineVolumeState));
  }, [engineVolumeState, setEngineVolume, dispatch]);

  return (
    <EngineWrapper>
      <label htmlFor="engine">Շարժիչի ծավալ, սմ<sup>3</sup></label>
      <input type="number" id='engine' step="0.1" maxLength='100' placeholder='1.8' value={engineVolumeState} onChange={handleSelectEngineVolume} required={formState.isRequired} disabled={formState.isDisabled} />
    </EngineWrapper>
  )
}
