import React, { useState } from 'react'
import logoLight from '../../assets/4hmotors-small-light.png'
import { HeaderWrapper, Logo, FullDateWrapper } from './style'
import { useSelector } from 'react-redux'
export const Header = () => {
  const {amd} = useSelector((state) => state.rate)
  const [date, setDate] = useState()

  setInterval(() => {
    getFormattedDate()
  }, 1000)
  const getFormattedDate = () => {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    setDate(`${year}/${month}/${day} ${hours}:${minutes}:${seconds}`);
  };
  return (
    <HeaderWrapper>
      <Logo className='logo'>
        <img src={logoLight} alt="logo" />
      </Logo>
      <FullDateWrapper className='date_wrapper'><p>{date}</p><span>1 USD = {Math.round(amd+3)} AMD</span></FullDateWrapper>
    </HeaderWrapper>
  )
}
