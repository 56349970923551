import { ecoTaxData } from "./customsDuty"
import { gasoline } from "./customsDuty"
import { diesel } from "./customsDuty"
import { hybrid } from "./customsDuty"
export const getCustomsValue = (vehiclePrice, currentShippingPrice, auctionFee) => {

    return vehiclePrice + currentShippingPrice + auctionFee
}

export const getCustomsDuty = (vehiclePrice, currentShippingPrice, auctionFee, engineType, carAge, vehicleType, engineVolume, highGroundClearance, pickupTonnage) => {
    const currentYear = new Date().getFullYear();
    let typeOfEngine = '';
    let ageOfCar = '';
    let typeOfVehicle = '';
    let percentageVal = 0;
    let calcWithDollarVal = 0;
    let tonnageOfPickup = ''
    if (engineType === 'gasoline') {
        typeOfEngine = gasoline;
    } else if (engineType === 'diesel') {
        typeOfEngine = diesel;
    } else if (engineType === 'hybrid') {
        typeOfEngine = hybrid;
    } else if (engineType === 'fullElectric') {
        return 0
    }

    if (carAge >= currentYear - 3) {
        ageOfCar = 'toThreeYears'
    } else if (carAge >= currentYear - 5 && carAge < currentYear - 3) {
        ageOfCar = 'toFiveYears'
    } else if (carAge >= currentYear - 7 && carAge < currentYear - 5) {
        ageOfCar = 'toSevenYears'
    } else {
        ageOfCar = 'afterSevenYears'
    }

    if (highGroundClearance) {
        typeOfVehicle = 'highGroundClearance'
    } else {
        if (vehicleType === 'sedan' || vehicleType === 'suv' || vehicleType === 'bigSuv' | vehicleType === 'minivan') {
            typeOfVehicle = 'sedan'
        } else if (vehicleType === 'motorcycle') {
            typeOfVehicle = 'motorcycle'
        } else if (vehicleType === 'pickup') {
            typeOfVehicle = 'pickup'
            if(engineType === 'diesel'){
                if (pickupTonnage === 'toFiveTonne') {
                    tonnageOfPickup = 'toFiveTonne'
                } else if (pickupTonnage === 'toTwentyTonne') {
                    tonnageOfPickup = 'toTwentyTonne'
                } else if (pickupTonnage === 'afterTwentyTonne') {
                    tonnageOfPickup = 'afterTwentyTonne'
                }
            }else{
                if (pickupTonnage === 'toFiveTonne') {
                    tonnageOfPickup = 'toFiveTonne'
                } else if (pickupTonnage === 'toTwentyTonne') {
                    tonnageOfPickup = 'afterFiveTonne'
                } else if (pickupTonnage === 'afterTwentyTonne') {
                    tonnageOfPickup = 'afterFiveTonne'
                }
            }
        }
    }
    const customsValue = getCustomsValue(vehiclePrice, currentShippingPrice, auctionFee)
    const insurancePrice = getInsurance(customsValue)
    const total = customsValue + insurancePrice
    if (engineType === 'hybrid') {
        let result = 0
        for (let range in typeOfEngine) {
            let [min, max] = range.split(' ').map(Number);
            if (carAge >= min && carAge < max) {
                result = typeOfEngine[range];
            }
        }
        console.log(result)
        const calcWithP = (total / 100) * result
        console.log(calcWithP)
        return Math.round(calcWithP)
    } else {

        let selectedValue = typeOfEngine[ageOfCar][typeOfVehicle]
        if (typeOfVehicle === 'pickup') {
            selectedValue = typeOfEngine[ageOfCar][typeOfVehicle][tonnageOfPickup]
        }
        if (typeOfVehicle === 'motorcycle' && typeOfEngine === 'gasoline') {
            selectedValue = typeOfEngine[ageOfCar][typeOfVehicle]
        }
        if (selectedValue?.percentage) {
            for (let range in selectedValue.percentage) {
                let [min, max] = range.split(' ').map(Number);
                if (engineVolume >= min && engineVolume < max) {
                    percentageVal = selectedValue.percentage[range];
                }
            }
        }

        if (selectedValue?.calcWithDollar) {
            for (let range in selectedValue.calcWithDollar) {
                let [min, max] = range.split(' ').map(Number);
                if (engineVolume >= min && engineVolume < max) {
                    calcWithDollarVal = selectedValue.calcWithDollar[range];
                }
            }
        }
        if (selectedValue) {
            for (let range in selectedValue) {
                let [min, max] = range.split(' ').map(Number);
                if (engineVolume >= min && engineVolume < max) {
                    percentageVal = selectedValue[range];
                }
            }
            
        }


        const calcWithP = (total / 100) * percentageVal
        const calcWithD = engineVolume * 1000 * calcWithDollarVal
        
        if (calcWithD > calcWithP) {
            const result = Math.round(calcWithD)
            return result
        } else {
            const result = Math.round(calcWithP)
            return result
        }

    }
}

export const getValueAddedTax = (customsDuty, customsValue) => {
    const totalValue = customsDuty + customsValue
    const result = Math.round((totalValue / 100) * 20)
    if(customsDuty === 0){
        return 0
    }else{
        return result
    }
}
export const getInsurance = (customsValue) => {
    const result = Math.round((customsValue / 100) * 1.2)
    return result
}
export const getEcoTax = (carYear, vehiclePrice, currentShippingPrice, auctionFee) => {
    const currentYear = new Date().getFullYear();
    const carAge = currentYear - carYear;
    let exoTax = 0;
    for (let range in ecoTaxData) {
        let [min, max] = range.split(' ').map(Number);
        if (carAge >= min && carAge < max) {
            exoTax = ecoTaxData[range];
        }
    }
    const customsValue = getCustomsValue(vehiclePrice, currentShippingPrice, auctionFee)
    const calc = (customsValue / 100) * exoTax

    return Math.round(calc)
}

export const getOtherTaxes = (insurance, customsValue, customsDuty) => {
    const total = insurance + customsDuty + customsValue;
    const result = Math.round((((total / 100) * 20)/100)*20)
    return result
}