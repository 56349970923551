import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Layout } from './pages';
import GlobalStyle from './util/globalStyles';
import { Provider } from 'react-redux';
import { store } from './store/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <GlobalStyle/>
    <Layout/>
    </Provider>
  </React.StrictMode>
);

