import { shippingRates, liveBitFees } from "./biddingFees";

export function getShippingRate(amount) {
    amount = parseInt(amount.toString().replace(/[^\d]/g, ''), 10);
    if (amount > 15000) {
        return amount / 100 * 6
    } else {
        for (let range in shippingRates) {
            let [min, max] = range.split(' ');
            min = parseInt(min, 10);
            max = max === '+' ? Infinity : parseInt(max, 10);
            if (amount >= min && amount <= max) {
                return shippingRates[range];
            }
        }
    }

    return null;
}

export function getLiveBitFee(amount) {
    amount = parseInt(amount.toString().replace(/[^\d]/g, ''), 10);
    if (amount > 8000) {
        return 149.00
    } else {
        for (let range in liveBitFees) {
            let [min, max] = range.split(' ');
            min = parseInt(min, 10);
            max = max === '+' ? Infinity : parseInt(max, 10);
            if (amount >= min && amount <= max) {
                return liveBitFees[range];
            }
        }
        return null
    }
}