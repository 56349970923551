// src/features/counter/counterSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const carDataSlice = createSlice({
  name: 'carData',
  initialState: {
    engineType: null,
    carAge: null,
    engineVolume: null,
    vehicleType: null,
    pickupTonnage:null,
    highGroundClearance: false
  },
  reducers: {
    setEngineType: (state, action) => {
      state.engineType = action.payload;
    },
    setCarAge: (state, action) => {
      state.carAge = action.payload;
    },
    setEngineVolume: (state, action) => {
      state.engineVolume = action.payload;
    },
    setVehicleType: (state, action) => {
      state.vehicleType = action.payload;
    },
    setPickupTonnage: (state, action) => {
      state.pickupTonnage = action.payload;
    },
    setHighGroundClearance: (state, action) => {
      state.highGroundClearance = action.payload;
    },
  },
});

// Export the actions generated by createSlice
export const { setEngineType, setCarAge, setEngineVolume, setVehicleType,setPickupTonnage,setHighGroundClearance } = carDataSlice.actions;

// Export the reducer to be included in the store
export default carDataSlice.reducer;
