import React, { useEffect, useState, useRef } from 'react'
import { SelectWrapper } from './style'
import { AuctionDataSlice } from '../../store/slices/auctionDataSlice'
import { useDispatch, useSelector } from 'react-redux'
import { DataList } from './DataList'
export const Location = () => {
    const { locations } = useSelector((state) => state.locationData)
    const { setShippingPrice } = AuctionDataSlice.actions
    const dispatch = useDispatch()
    const [selectedOption, setSelectedOption] = useState({
        sedan: 0,
        suv: 0,
        bigSuv: 0,
        van: 0,
        pickup: 0,
        motorcycle: 0
    })

    const handleSelectedChange = (option) => {

        setSelectedOption({
            sedan: parseInt(option.data.sedan),
            suv: parseInt(option.data.suv),
            bigSuv: parseInt(option.data.big_suv),
            van: parseInt(option.data.van),
            pickup: parseInt(option.data.pickup),
            motorcycle: parseInt(option.data.motorcycle),
        });
    };
    
    useEffect(() => {
        if (selectedOption) {
            dispatch(setShippingPrice(selectedOption))
        } else {
            return null
        }
    }, [selectedOption, dispatch, setShippingPrice])

    const [inputValue, setInputValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setIsDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);

        if (value) {
            const filtered = locations.filter(option =>
                option.label.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredOptions(filtered);
            setIsDropdownVisible(true);
        } else {
            setFilteredOptions([]);
            setIsDropdownVisible(false);
        }
    };

    const handleInputClick = () => {
        setFilteredOptions(locations);
        setIsDropdownVisible(true);
    };

    const handleOptionClick = (option) => {
        setInputValue(option.label);
        setIsDropdownVisible(false);
        handleSelectedChange(option)
    };
    return (
        <SelectWrapper>
            <DataList />
            <div className="custom-datalist" ref={inputRef}>
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onClick={handleInputClick}
                    placeholder=""
                />
                {isDropdownVisible && filteredOptions.length > 0 && (
                    <div className="options-list">
                        {filteredOptions.map((option, index) => (
                            <p
                                key={index}
                                onClick={() => handleOptionClick(option)}
                                value={option.value}
                                sedan={option.data.sedan}
                                suv={option.data.suv}
                                big_suv={option.data.bigSuv}
                                van={option.data.van}
                                pickup={option.data.pickup}
                                motorcycle={option.data.motorcycle}
                            >
                                {option.label}
                            </p>
                        ))}
                    </div>
                )}
            </div>
        </SelectWrapper>

    )
}
