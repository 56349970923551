import React, {useState, useEffect} from 'react'
import {AuctionList} from '../../components/auctionList'
import {CalculatorWrapper, FormWrapper} from './style'
import {AuctionDataForm} from '../../components/auctionDataForm'
import {CarDataForm} from '../../components/carDataForm'
import {Button} from '../../components/button'
import {customsValuesSlice} from '../../store/slices/customsValuesSlice'
import {useDispatch, useSelector} from 'react-redux'
import {
    getCustomsDuty,
    getCustomsValue,
    getEcoTax,
    getInsurance,
    getValueAddedTax,
    getOtherTaxes
} from '../../util/calculate'

export const Calculator = () => {
    const {
        carAge,
        engineType,
        vehicleType,
        engineVolume,
        highGroundClearance,
        pickupTonnage
    } = useSelector((state) => state.carData);
    const {vehiclePrice, currentShippingPrice, auctionFee} = useSelector((state) => state.auctionData)
    const {
        setEcoTax,
        setCustomsValue,
        setInsurance,
        setCustomsDuty,
        setVAT,
        setTotal,
        setReadyToShow,
        setOtherTaxes
    } = customsValuesSlice.actions
    const dispatch = useDispatch()
    const [ecoTaxState, setEcoTaxState] = useState(0)
    const [customsDutyState, setCustomsDutyState] = useState(0)
    const [customsValueState, setCustomsValueState] = useState(0)
    const [VATState, setVATState] = useState(0)
    const [insuranceState, setInsuranceState] = useState(0)
    const [otherTaxesState, setOtherTaxesState] = useState(0)
    const [isCalculated, setIsCalculated] = useState(false)
    const handleSubmit = (event) => {
        event.preventDefault();
        if (event) {
            const ecoTax = getEcoTax(carAge, vehiclePrice, currentShippingPrice, auctionFee)
            const customsDuty = getCustomsDuty(vehiclePrice, currentShippingPrice, auctionFee, engineType, carAge, vehicleType, engineVolume, highGroundClearance, pickupTonnage)
            const customsValue = getCustomsValue(vehiclePrice, currentShippingPrice, auctionFee)
            const VAT = getValueAddedTax(customsDuty, customsValue)
            const insurance = getInsurance(customsValue)
            const otherTaxes = getOtherTaxes(insurance, customsValue, customsDuty)
            setEcoTaxState(ecoTax)
            setCustomsDutyState(customsDuty)
            setCustomsValueState(customsValue)
            setVATState(VAT)
            setInsuranceState(insurance)
            setOtherTaxesState(otherTaxes)
            setIsCalculated(true)
        }
    };
    useEffect(() => {

        if (isCalculated) {
            dispatch(setEcoTax(ecoTaxState))
            dispatch(setCustomsDuty(customsDutyState))
            dispatch(setCustomsValue(customsValueState))
            dispatch(setVAT(VATState))
            dispatch(setTotal(ecoTaxState + customsDutyState + customsValueState + VATState + insuranceState + otherTaxesState+300))
            dispatch(setInsurance(insuranceState))
            dispatch(setOtherTaxes(otherTaxesState))
            dispatch(setReadyToShow(true))
        }
        // eslint-disable-next-line
    }, [isCalculated]);
    const handleChange = () => {
        dispatch(setEcoTax(0))
        dispatch(setCustomsDuty(0))
        dispatch(setCustomsValue(0))
        dispatch(setVAT(0))
        dispatch(setTotal(0))
        dispatch(setInsurance(0))
        dispatch(setOtherTaxes(0))
        dispatch(setReadyToShow(false))
        setIsCalculated(false)
    }
    return (
        <CalculatorWrapper>
            <AuctionList/>
            <form onSubmit={handleSubmit} onChange={handleChange} className='form'>
                <FormWrapper>
                    <AuctionDataForm/>
                    <CarDataForm/>
                    <Button/>
                </FormWrapper>

            </form>
        </CalculatorWrapper>
    )
}
