import { store } from "../store/store";
const state = store.getState().rate;
const usd = state.usd | 1.09

export const gasoline = {
    toThreeYears: {
        sedan: {
            "0.1 2.8": 15,
            "2.8 10000": 12.5
        },
        highGroundClearance: {
            "0.1 3.5": 15,
            "3.5 4.2": 12.5,
            "4.2 10000": 10
        },
        motorcycle: {
            "0.01 0.25": 14,
            "0.25 0.80": 15,
            "0.80 10000": 10
        },
        pickup: {
            toFiveTonne: {
                "0.1 2.8": 15,
                "2.8 10000": 12.5
            },
            afterFiveTonne: {
                "0.1 10000": 15
            }

        }
    },
    toFiveYears: {
        sedan: {
            percentage: {
                "0.1 10000": 20
            },
            calcWithDollar: {
                "0.1 1.0": 0.36 * usd,
                "1.0 1.5": 0.40 * usd,
                "1.5 1.8": 0.36 * usd,
                "1.8 2.3": 0.44 * usd,
                "2.3 3.0": 0.44 * usd,
                "3.0 10000": 0.80 * usd
            }
        },
        motorcycle: {
            "0.01 0.25": 14,
            "0.25 0.80": 15,
            "0.80 10000": 10
        },
        pickup: {
            toFiveTonne: {
                "0.1 2.8": 15,
                "2.8 10000": 15
            },
            afterFiveTonne: {
                "0.1 10000": 15
            }

        }
    },
    toSevenYears: {
        sedan: {
            percentage: {
                "0.1 10000": 20
            },
            calcWithDollar: {
                "0.1 1.0": 0.36 * usd,
                "1.0 1.5": 0.40 * usd,
                "1.5 1.8": 0.36 * usd,
                "1.8 2.3": 0.44 * usd,
                "2.3 3.0": 0.44 * usd,
                "3.0 10000": 0.80 * usd
            }
        },
        motorcycle: {
            "0.01 0.25": 14,
            "0.25 0.80": 15,
            "0.80 10000": 10
        },
        pickup: {
            toFiveTonne: {
                "0.1 2.8": 15,
                "2.8 10000": 15
            },
            afterFiveTonne: {
                "0.1 10000": 15
            }

        }


    },
    afterSevenYears: {
        sedan: {
            percentage: {
                "0.1 10000": 0.1
            },
            calcWithDollar: {
                "0.1 1.0": 1.4 * usd,
                "1.0 1.5": 1.5 * usd,
                "1.5 1.8": 1.6 * usd,
                "1.8 2.3": 2.2 * usd,
                "2.3 3.0": 2.2 * usd,
                "3.0 10000": 3.2 * usd
            }
        },
        motorcycle: {
            "0.01 0.25": 14,
            "0.25 0.80": 15,
            "0.80 10000": 10
        },
        pickup: {
            toFiveTonne: {
                "0.1 2.8": 1 * usd,
                "2.8 10000": 1 * usd
            },
            afterFiveTonne: {
                "0.1 10000": 1 * usd
            }

        }

    }
}
export const diesel = {
    toThreeYears: {
        sedan: {
            "0.1 10000": 15
        },
        pickup: {
            toFiveTonne: {
                "0.1 2.5": 10,
                "2.5 10000": 10
            },
            toTwentyTonne: {
                "0.1 10000": 15
            },
            afterTwentyTonne: {
                "0.1 10000": 5
            }

        }
    },
    toFiveYears: {
        sedan: {
            percentage: {
                "0.1 10000": 20
            },
            calcWithDollar: {
                "0.1 1.5": 0.32 * usd,
                "1.5 2.5": 0.40 * usd,
                "2.5 10000": 0.80 * usd,
            }
        },
        pickup: {
            toFiveTonne: {
                "0.1 2.5": 10,
                "2.5 10000": 10
            },
            toTwentyTonne: {
                "0.1 10000": 10
            },
            afterTwentyTonne: {
                "0.1 10000": 10
            }

        }
    },
    toSevenYears: {
        sedan: {
            percentage: {
                "0.1 10000": 20
            },
            calcWithDollar: {
                "0.1 1.5": 0.32 * usd,
                "1.5 2.5": 0.40 * usd,
                "2.5 10000": 0.80 * usd,
            }
        },
        pickup: {
            toFiveTonne: {
                percentage: {
                    "0.1 2.5": 10,
                    "2.5 10000": 10
                },
                calcWithDollar: {
                    "0.1 2.5": 0.13 * usd,
                    "2.5 10000": 0
                }
            },
            toTwentyTonne: {
                percentage: {
                    "0.1 2.5": 10,
                    "2.5 10000": 10
                },
                calcWithDollar: {
                    "0.1 2.5": 0.18 * usd,
                    "2.5 10000": 0.18 * usd
                }
            },
            afterTwentyTonne: {
                percentage: {
                    "0.1 2.5": 10,
                    "2.5 10000": 10
                },
                calcWithDollar: {
                    "0.1 2.5": 0,
                    "2.5 10000": 0
                }
            }

        }
    },
    afterSevenYears: {
        sedan: {
            percentage: {
                "0.1 10000": 0.1
            },
            calcWithDollar: {
                "0.1 1.5": 1.5 * usd,
                "1.5 2.5": 2.2 * usd,
                "2.5 10000": 3.2 * usd,
            }
        },
        pickup: {
            toFiveTonne: {
                percentage: {
                    "0.1 2.5": 0,
                    "2.5 10000": 0
                },
                calcWithDollar: {
                    "0.1 2.5": 1 * usd,
                    "2.5 10000": 1 * usd
                }
            },
            toTwentyTonne: {
                percentage: {
                    "0.1 2.5": 0,
                    "2.5 10000": 0
                },
                calcWithDollar: {
                    "0.1 2.5": 1 * usd,
                    "2.5 10000": 1 * usd
                }
            },
            afterTwentyTonne: {
                percentage: {
                    "0.1 2.5": 0,
                    "2.5 10000": 0
                },
                calcWithDollar: {
                    "0.1 2.5": 1 * usd,
                    "2.5 10000": 1 * usd
                }
            }

        }

    }
}

export const hybrid = {
    "0 3": 15,
    "3 10000": 20
}

export const ecoTaxData = {
    "0 3": 2,
    "3 5": 4,
    "5 10": 6,
    "10 15": 12,
    "15 10000": 24
}