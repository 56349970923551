import React, { useState, useEffect } from 'react'
import { TonnageWrapper } from '../style'
import { carDataSlice } from '../../../store/slices/carDataSlice';
import { useDispatch, useSelector } from 'react-redux';
export const TonnageInput = () => {
    const { vehicleType } = useSelector((state) => state.carData)
    const { setPickupTonnage } = carDataSlice.actions
    const dispatch = useDispatch()
    const [pickupTonnageState, setPickupTonnageState] = useState('')
    const [formState, setFormState] = useState({
        isRequired: false,
        isDisabled: true
    })
    useEffect(() => {
        if (vehicleType === 'pickup') {
            setFormState({
                isRequired: true,
                isDisabled: false
            })
        } else {
            setFormState({
                isRequired: false,
                isDisabled: true
            })
            dispatch(setPickupTonnage(null))
        }
        // eslint-disable-next-line
    }, [vehicleType])

    const handleSelectTonnage = (e) => {
        if (e.target.value) {
            setPickupTonnageState(e.target.value)
        } else { setPickupTonnageState(null) }
    }

    useEffect(() => {
        dispatch(setPickupTonnage(pickupTonnageState))
        // eslint-disable-next-line
    }, [pickupTonnageState])
    return (
        <TonnageWrapper>
            <label htmlFor="tonnage">Տոննաժ</label>
            <select name="tonnage" id="tonnage" onChange={handleSelectTonnage} value={formState.isDisabled?'':pickupTonnageState} required={formState.isRequired} disabled={formState.isDisabled} >
                <option style={{ display: 'none' }} value=""></option>
                <option value="toFiveTonne">Մինչև 5 տոննա</option>
                <option value="toTwentyTonne">5-ից մինչև 20 տոննա</option>
                <option value="afterTwentyTonne">20 տոննայից ավել</option>
            </select>
        </TonnageWrapper>
    )
}
