export const shippingRates = {
    "0 49": 1.00,
    "50 99": 1.00,
    "100 199": 25.00,
    "200 299": 60.00,
    "300 349": 80.00,
    "350 399": 90.00,
    "400 449": 120.00,
    "450 499": 130.00,
    "500 549": 140.00,
    "550 599": 150.00,
    "600 699": 165.00,
    "700 799": 185.00,
    "800 899": 200.00,
    "900 999": 215.00,
    "1000 1199": 230.00,
    "1200 1299": 255.00,
    "1300 1399": 275.00,
    "1400 1499": 280.00,
    "1500 1599": 290.00,
    "1600 1699": 305.00,
    "1700 1799": 315.00,
    "1800 1999": 325.00,
    "2000 2399": 355.00,
    "2400 2499": 380.00,
    "2500 2999": 400.00,
    "3000 3499": 450.00,
    "3500 3999": 500.00,
    "4000 4499": 600.00,
    "4500 4999": 625.00,
    "5000 5999": 650.00,
    "6000 6499": 675.00,
    "6500 6999": 675.00,
    "7000 7499": 700.00,
    "7500 7999": 700.00,
    "8000 8499": 725.00,
    "8500 8999": 725.00,
    "9000 9999": 725.00,
    "10000 10499": 750.00,
    "10500 10999": 750.00,
    "11000 11499": 750.00,
    "11500 11999": 760.00,
    "12000 12499": 775.00,
    "12500 14999": 790.00,
    "15000+": 6.00
};

export const liveBitFees = {
    "0 99": 0.00,
    "100 499": 49.00,
    "500 999": 59.00,
    "1000 1499": 79.00,
    "1500 1999": 89.00,
    "2000 3999": 99.00,
    "4000 5999": 109.00,
    "6000 7999": 139.00,
    "8000+": 149.00
};