import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Calculator } from './calculator'
import { fetchExchangeRate } from '../util/api';
import { rateSlice } from '../store/slices/rateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { CalculatedResult } from './calculatedResult';
import { Footer } from './footer';
import { Header } from './header'
import bgImage from '../assets/bg-image.jpg';
const HomeWrapper = styled.div`
background-image: url(${bgImage});
  margin-top: 100px;
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 780px) {
    & {
      margin-top: 75px;
    }
  }
`
export const Layout = () => {
  const { readyToShow } = useSelector((state) => state.customsValue)
  const { setAMD, setUSD } = rateSlice.actions
  const dispatch = useDispatch()
  useEffect(() => {

    fetchExchangeRate('USD', 'AMD').then(rate => {
      if (rate !== null) {
        dispatch(setAMD(rate))
      } else {
        console.log('Failed to fetch the exchange rate.');
      }
    });
    fetchExchangeRate('EUR', 'USD').then(rate => {
      if (rate !== null) {
        dispatch(setUSD(rate))
      } else {
        console.log('Failed to fetch the exchange rate.');
      }
    });
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Header />
      <HomeWrapper>
        <Calculator />
        {readyToShow ?
          <CalculatedResult />
          : null}
      </HomeWrapper>
      <Footer />
    </>
  )
}
