import styled from 'styled-components'

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding: 15px;
`
export const VehiclePriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .vehicle_price_input {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        width: 95%;
        border-radius: 20px;
        align-content: space-between;
        justify-content: space-between;
        background-color: #f5f5f5;
        padding: 20px;
    }

    .vehicle_price {
        height: 55px;
        display: flex;
        align-items: center;
    }
`
export const VehiclePrice = styled.input`
`
export const AuctionFee = styled.div`
    visibility: hidden;
    background-color: #fff;
    color: #5c81d6;
    width: 50%;
    display: flex;
    border-radius: 0;
    padding: 9px 25px 10px 25px;
    font-weight: 600;
    height: 40px;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    transform: translateY(45px);
    opacity: 0;
    transition: 0.2s ease-in-out;

    &.visible {
        visibility: visible;
        background-color: #c4f5ad;
        border-radius: 10px;
        margin-bottom: 10px;
        transform: translateY(0);
        opacity: 1;
        transition: 0.2s ease-in-out;
    }

    @media screen and (max-width: 780px) {
        width: 50%;
        display: flex;
        height: 70px;
        padding: 5px 10px 5px 10px;
        justify-content: center;
        align-items: stretch;
        font-size: 12px;
        flex-direction: column;
    }

    & p {
        font-size: 20px;
        width: 120px;
        overflow: hidden;
        border-left: 1px solid #5c81d6;
        margin-left: 5px;
        padding-left: 5px;
    }
`
export const AuctionLocationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .vehicle_location_input {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        width: 95%;
        border-radius: 20px;
        align-content: space-between;
        justify-content: space-between;
        background-color: #f5f5f5;
        padding: 20px;
    }

    & span {
        height: 55px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 780px) {
            height: fit-content;
        }
    }
`
export const AuctionLocation = styled.div`
    visibility: hidden;
    background-color: #fff;
    color: #5c81d6;
    width: 50%;
    display: flex;
    border-radius: 0;
    padding: 9px 25px 10px 25px;
    font-weight: 600;
    height: 40px;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    transform: translateY(45px);
    opacity: 0;
    transition: 0.2s ease-in-out;

    &.visible {
        visibility: visible;
        background-color: #c4f5ad;
        border-radius: 10px;
        margin-bottom: 10px;
        transform: translateY(0);
        opacity: 1;
        transition: 0.2s ease-in-out;
    }

    @media screen and (max-width: 780px) {
        width: 50%;
        display: flex;
        height: 70px;
        padding: 5px 10px 5px 10px;
        justify-content: center;
        align-items: stretch;
        flex-direction: column;
        font-size: 12px;
    }

    & p {
        font-size: 20px;
        width: 80px;
        overflow: hidden;
        border-left: 1px solid #5c81d6;
        margin-left: 5px;
        padding-left: 5px;
    }
`
export const ShippingPrice = styled.div`
    height: 30px;
    font-size: 18px;
    border: 1px solid black;
`